.st0 {
  display: none;
}
.st1 {
  font-family: 'ZhiMangXing-Regular';
}
.st2 {
  font-size: 246.2027px;
}
.st3 {
  font-size: 340.7668px;
}
.st4 {
  display: inline;
}

.autograph {
  width: 80px;
  /* display: none; */
  opacity: 0;
}

.autograph.a_letter {
  width: 40px;
  /* display: none; */
  opacity: 0;
}
.autograph.n_letter {
  width: 20px;
  /* display: none; */
  opacity: 0;
}

.autograph__path {
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.animated .autograph__path {
  opacity: 1;
  -webkit-animation-name: line;
  animation-name: line;
}

@-webkit-keyframes line {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes line {
  100% {
    stroke-dashoffset: 0;
  }
}
