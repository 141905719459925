/*
.card-contact svg {
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  width: 60px;
  min-height: 34px;
  margin-right: 12px;
  transition: 0.3s;
  padding-right: 12px;
  border-right: 1px solid #dfe2ec;
}
*/

.botom-contact-title {
  color: rgb(32, 32, 32);
  /*
font-family:K2D, sans-serif;
*/
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 42px;

  font-weight: 600;
  line-height: 55px;
}

.botom-contact-subTitle {
  color: rgb(149, 149, 149);
  display: block;
  font-family: Lato, sans-serif;
  font-size: 20px;

  font-weight: 300;
  height: 53px;
  line-height: 28.57px;
  margin-bottom: 20px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  padding-bottom: 10px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 15px;
  text-align: center;
}

.contact-text ul li {
  color: rgb(255, 58, 70);
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-weight: 400;
  height: 34px;
  line-height: 34.284px;
  margin-bottom: 24px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  outline-color: rgb(255, 58, 70);
  text-align: center;
}

.arch-contact .form-wrapper .contact-text p {
  font-size: 22px;
  padding: 0;
}

.contact-text ul li {
  margin-bottom: 50px;
}

.arch-contact .form-wrapper .contact-text {
  padding-left: 0px!important;
}

@media (max-width: 1440px) {
  .arch-contact .form-wrapper {
    bottom: -280px;
  }

  .botom-contact-title {
    padding: 0 115px 50px 115px;
  }

  .arch-contact .form-wrapper .contact-text p {
    font-size: 22px;
    padding: 0;
  }

  .contact-text ul li {
    margin-bottom: 50px;
  }
}

@media (max-width: 1024px) {
  .arch-contact .form-wrapper {
    bottom: -280px;
  }

  .botom-contact-title {
    padding: 0 40px 50px 40px;
  }

  .arch-contact .form-wrapper .contact-text p {
    font-size: 22px;
    padding: 0;
  }

  .contact-text ul li {
    margin-bottom: 50px;
  }
}


@media (max-width: 991px) {
.arch-contact {
    height: auto;
    padding: 100px 0;
    margin: 0;
  }

  .arch-contact .form-wrapper .contact-text p {
    font-size: 20px;
    padding: 0;
  }

  .contact-text ul li {
    margin-bottom: 40px;
  }
}

.contact-text ul li span {
  color: #c5c5c5;
  font-weight: 380;
}

@media screen and (max-width: 414px) {
  .agn-contact-banner {
    padding: 60px 0 100px;
  }

  .arch-contact {
    padding: 0;
    margin: 40px 15px 0;
  }

  .botom-contact-title {
    padding-bottom: 40px;
  }

  .arch-contact .form-wrapper .contact-text p {
    font-size: 18px;
    padding: 0;
  }

  .contact-text ul li {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 375px) {
  .agn-contact-banner {
    padding: 60px 0 100px;
  }

  .arch-contact {
    padding: 0;
    margin: 40px 15px 0;
  }

  .botom-contact-title {
    font-size: 38px;
    line-height: 48px;
    padding-bottom: 30px;
  }

  .arch-contact .form-wrapper .contact-text p {
    font-size: 18px;
    padding: 0;
  }
}

