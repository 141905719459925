/* CSS Document */

/**
* Importing necessary  Styles.
**/
@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,900,700|Playfair+Display:700');
@import url('../fonts/circular-std/css/circular-std.css');
/*----bootstrap css ----- */
@import url('../vendor/bootstrap/css/bootstrap.min.css');
/*------- menu ------*/
@import url('../vendor/mega-menu/assets/css/menu.css');
/*----font awesome -------*/
/* @import url('../fonts/font-awesome/css/font-awesome.min.css'); */
/*----owl-carousel css
@import url('../vendor/owl-carousel/owl.carousel.css');
@import url('../vendor/owl-carousel/owl.theme.css');
@import url('../vendor/owl-carousel/animate.css');
----*/

/*-------- AOS css ------*/
@import url('../vendor/aos-next/dist/aos.css');
/*----------- Fancybox css -------*/
@import url('../vendor/fancybox/dist/jquery.fancybox.min.css');
/*------------- Map -------------*/
@import url('../vendor/sanzzy-map/dist/snazzy-info-window.min.css');
/*----Flaticon-------*/
@import url('../fonts/icon/font/flaticon.css');
/*---------------- Custom Animation -------------*/
@import url('custom-animation.css');
/*---------------- Animated Headline -------------*/
@import url('../vendor/animated-headline-master/style.css');
/*---------------- Modal -------------*/
@import url('../vendor/iziModal-master/iziModal.css');
/*--------- Jquery ui ---------*/
@import url('../vendor/jquery-ui/jquery-ui.min.css');
/*--------- Select js ---------*/
@import url('../vendor/selectize.js/selectize.css');
/*--------- Tabs ---------*/
@import url('../vendor/tabs/tabs.css');

/*** 

====================================================================
  Loading Transition
====================================================================

 ***/
/* Preloader */
.ctn-preloader {
  align-items: center;
  -webkit-align-items: center;
  display: flex;
  display: -ms-flexbox;
  height: 100%;
  justify-content: center;
  -webkit-justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;
  background: #fff;
}
.ctn-preloader .animation-preloader {
  position: absolute;
  z-index: 100;
}
/* Spinner cargando */
.ctn-preloader .animation-preloader .spinner {
  animation: spinner 1s infinite linear;
  border-radius: 50%;
  border: 3px solid rgba(255, 57, 70, 0.15);
  border-top-color: #ff3946;
  height: 170px;
  margin: 0 auto 45px auto;
  width: 170px;
}
/* Texto cargando */
.ctn-preloader .animation-preloader .txt-loading {
  text-align: center;
  user-select: none;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:before {
  animation: letters-loading 4s infinite;
  color: #ff3946;
  content: attr(data-text-preloader);
  left: 0;
  opacity: 0;
  top: 0;
  line-height: 70px;
  position: absolute;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  letter-spacing: 15px;
  display: inline-block;
  color: rgba(255, 57, 70, 0.15);
  position: relative;
  font-size: 70px;
  line-height: 70px;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(2):before {
  animation-delay: 0.2s;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(3):before {
  animation-delay: 0.4s;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(4):before {
  animation-delay: 0.6s;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(5):before {
  animation-delay: 0.8s;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(6):before {
  animation-delay: 1s;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(7):before {
  animation-delay: 1.2s;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(8):before {
  animation-delay: 1.4s;
}
.ctn-preloader .loader-section {
  background-color: #ffffff;
  height: 100%;
  position: fixed;
  top: 0;
  width: calc(50% + 1px);
}
.loaded .animation-preloader {
  opacity: 0;
  transition: 0.3s ease-out;
}
/* Animación del preloader */
@keyframes spinner {
  to {
    transform: rotateZ(360deg);
  }
}
@keyframes letters-loading {
  0%,
  75%,
  100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}

@media screen and (max-width: 767px) {
  .ctn-preloader .animation-preloader .spinner {
    height: 8em;
    width: 8em;
  }
}
@media screen and (max-width: 500px) {
  .ctn-preloader .animation-preloader .spinner {
    height: 7em;
    width: 7em;
  }
  .ctn-preloader .animation-preloader .txt-loading .letters-loading {
    font-size: 40px;
    letter-spacing: 10px;
  }
}

/*==================== Click Top ====================*/
.scroll-top {
  width: 25px;
  height: 25px;
  position: fixed;
  bottom: 10px;
  right: 5px;
  z-index: 99;
  text-align: center;
  color: #fff;
  font-size: 18px;
  display: none;
  cursor: pointer;
  border-radius: 3px;
}
.scroll-top:after {
  position: absolute;
  z-index: -1;
  content: '';
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 1;
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
}
/*______________________ Global style ___________________*/

@font-face {
  font-family: 'font-awesome';
  src: url('../fonts/font-awesome/fonts/fontawesome-webfont.ttf');
  src: url('../fonts/font-awesome/fonts/fontawesome-webfont.eot'),
    /* IE9 Compat Modes */ url('../fonts/font-awesome/fonts/fontawesome-webfont.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('../fonts/font-awesome/fonts/fontawesome-webfont.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('../fonts/font-awesome/fonts/fontawesome-webfont.svg')
      format('svg'); /* Legacy iOS */
}
body {
  font-family: 'CircularStd-book';
  font-weight: normal;
  color: #57667e;
  font-size: 17px;
  position: relative;
  top: 0 !important;
}
.font-lato {
  font-family: 'Lato', sans-serif;
  font-weight: normal;
}
.font-k2d {
  font-family: 'K2D', sans-serif !important;
}
.main-page-wrapper {
  overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  padding: 0;
}
.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  color: #233d63;
  font-weight: normal;
}
.h1,
h1 {
  font-size: 60px;
}
.h2,
h2 {
  font-size: 48px;
}
.h3,
h3 {
  font-size: 40px;
}
.h4,
h4 {
  font-size: 30px;
}
.h5,
h5 {
  font-size: 22px;
}
.h6,
h6 {
  font-size: 18px;
}
p {
  line-height: 28px;
}
ul {
  list-style-type: none;
}
body a {
  text-decoration: none;
  display: inline-block;
}
a:hover,
a:focus,
a:visited {
  text-decoration: none;
  outline: none;
}
img {
  max-width: 100%;
  display: block;
}
button {
  border: none;
  outline: none;
  box-shadow: none;
  display: block;
  padding: 0;
  cursor: pointer;
  background: transparent;
}
button:focus {
  outline: none;
}
input,
textarea {
  outline: none;
  box-shadow: none;
  transition: all 0.3s ease-in-out;
}

/*----------- Prefix -----------*/
.full-width-container {
  padding-left: 60px;
  padding-right: 60px;
}
.demo-container-900 {
  max-width: 900px;
  margin: 0 auto;
}
.demo-container-1100 {
  max-width: 1100px;
  margin: 0 auto;
}
.p0 {
  padding: 0 !important;
}
.pt-15 {
  padding-top: 15px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-90 {
  padding-top: 90px;
}
.pt-150 {
  padding-top: 150px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-70 {
  padding-bottom: 70px;
}
.pb-100 {
  padding-bottom: 100px;
}
.pb-150 {
  padding-bottom: 150px;
}
.pb-200 {
  padding-bottom: 200px;
}
.mt-70 {
  margin-top: 70px;
}
.mt-95 {
  margin-top: 95px;
}
.mt-150 {
  margin-top: 150px;
}
.mt-200 {
  margin-top: 200px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mb-150 {
  margin-bottom: 150px;
}
.mb-170 {
  margin-bottom: 170px;
}
.mb-200 {
  margin-bottom: 200px;
}
.m0 {
  margin: 0 !important;
}
.row.gutter-80 {
  margin: 0 -40px;
}
.row.gutter-80 [class*='col-'] {
  padding: 0 40px;
}
.row.gutter-50 {
  margin: 0 -25px;
}
.row.gutter-50 [class*='col-'] {
  padding: 0 25px;
}
/*----------------- Theme button ------------------*/
body .solid-button-one {
  min-width: 188px;
  line-height: 48px;
  border-style: solid;
  border-width: 2px;
  border-radius: 30px;
  padding: 0 35px;
  text-align: center;
  color: #fff;
  text-transform: capitalize;
  box-shadow: 0px 13px 27px 0px rgba(163, 48, 53, 0.25);
}
body .solid-button-one:hover {
  background: #fff;
  box-shadow: none;
}
body .solid-button-one .icon-right {
  vertical-align: -1px;
  margin-left: 5px;
}
body .solid-button-one .icon-left {
  vertical-align: -1px;
  margin-right: 5px;
}
body .video-button-one {
  font-size: 18px;
  line-height: 55px;
  color: #57667e;
}
body .video-button-one i {
  font-size: 50px;
  color: #d8d8d8;
  vertical-align: middle;
}
body .video-button-one .icon-right {
  margin-left: 10px;
}
body .video-button-one .icon-left {
  margin-right: 10px;
}
body .theme-button-one {
  font-family: 'CircularStdmed';
  width: 220px;
  line-height: 56px;
  text-align: center;
  font-size: 19px;
  border-width: 2px;
  border-style: solid;
  border-radius: 30px;
  position: relative;
}
body .theme-button-one:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 30px;
  z-index: -1;
  transform: scale(0, 1);
}
body .theme-button-one:hover {
  color: #fff;
  border-color: #fff;
}
body .theme-button-one:hover:before {
  transform: scale(1, 1);
}
body .theme-button-two {
  text-transform: capitalize;
  text-align: center;
  font-size: 18px;
  color: #fff;
  line-height: 50px;
  padding: 0 40px;
  position: relative;
  z-index: 1;
}
body .theme-button-two:before {
  content: '';
  position: absolute;
  top: 4px;
  right: 4px;
  bottom: 4px;
  left: 4px;
  border: 1px solid #fff;
  opacity: 0;
}
body .theme-button-two:hover:before {
  opacity: 1;
}
body .theme-button-two .icon-right {
  vertical-align: -1px;
  margin-left: 5px;
}
body .theme-button-two .icon-left {
  vertical-align: -1px;
  margin-right: 5px;
}
body .line-button-one {
  text-transform: capitalize;
  line-height: 48px;
  border-style: solid;
  border-width: 2px;
  border-radius: 30px;
  text-align: center;
  padding: 0 35px;
  min-width: 180px;
}
body .line-button-one:hover {
  box-shadow: 0px 13px 27px 0px rgba(163, 48, 53, 0.25);
  color: #fff;
}
body .line-button-two {
  text-transform: capitalize;
  line-height: 48px;
  border: 1px solid #e0e9ef;
  border-radius: 25px;
  padding: 0 35px;
  min-width: 180px;
  text-align: center;
  font-size: 18px;
  color: #233d63;
}
body .line-button-two:hover,
.agn-our-pricing .table-wrapper .pr-column:hover .line-button-two {
  color: #fff;
  box-shadow: 0px 13px 28px 0px rgba(163, 48, 53, 0.25);
}
body .line-button-three {
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  line-height: 48px;
  border: 1px solid #171717;
  min-width: 180px;
  text-align: center;
  color: #141414;
}
body .line-button-three:hover {
  color: #fff;
}
body .white-shdw-button {
  text-transform: capitalize;
  line-height: 55px;
  display: inline-block;
  text-align: center;
  font-size: 18px;
  box-shadow: 0px 10px 25px 0px rgba(123, 147, 171, 0.15);
  border-radius: 3px;
  padding: 0 33px;
  background: #fff;
}
body .white-shdw-button .icon {
  font-size: 25px;
  vertical-align: middle;
  margin-left: 10px;
}
body .white-shdw-button:hover {
  color: #fff;
  box-shadow: 0px 13px 28px 0px rgba(163, 48, 53, 0.25);
}
body .dark-button-one {
  font-family: 'CircularStdmed';
  line-height: 50px;
  padding: 0 40px;
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase;
  background: #3b3b3b;
}
/*--------------------- Transition --------------------*/
.tran3s,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
.svg path,
body .video-button-one,
body .video-button-one i,
body .line-button-two,
body .line-button-three,
.agn-what-we-do .single-block .more-button,
.agn-our-gallery .main-wrapper .img-box .hover-content,
.owl-theme .owl-nav [class*='owl-'],
.theme-footer-three .social-icon li a,
.theme-menu-one .logo,
.theme-main-menu .header-right-widget,
.agn-our-gallery .main-wrapper .view-gallery,
.sass-contact-banner .subscribe-button,
.language-switcher .dropdown-menu ul li a,
.theme-footer-one .top-footer .footer-list ul li a,
.theme-footer-one .top-footer .footer-information ul li a,
.theme-footer-one .bottom-footer ul li a,
.panel .panel-heading .panel-title a:before,
.theme-menu-two .quote-button,
.theme-menu-two .logo,
.seo-what-we-do .single-block .wrapper,
.seo-our-goal .text-wrapper .request-button,
.our-service-seo .single-block .more-button,
.our-service-seo .single-block p,
.seo-our-pricing .single-pr-table .pr-footer .plan-button:before,
.seo-our-pricing .single-pr-table .pr-footer .plan-button,
.navbar-nav .nav-item .nav-link,
.seo-our-pricing .single-pr-table .pr-footer .trial-button,
.seo-contact-banner .contact-button,
.theme-menu-three .header-right-widget .social-icon li a,
.nav-tabs .nav-link,
.theme-footer-four .social-icon li a,
.about-me-portfo .inner-content .button-group li a,
.our-service-portfo .service-block .read-more,
.isotop-menu-wrapper li,
.our-project-portfo .text-content .gallery-button,
.btn,
.portfo-footer form .input-group label,
.portfo-footer form button,
.portfo-footer .copyright-text .social-icon li a,
.theme-menu-one .header-right-widget .call-us a,
.theme-menu-one .navbar-nav .login-button a,
.our-service-sass .service-block .icon-s,
.our-service-sass .service-block p,
#theme-banner-four .main-wrapper .button-group .more-button,
.checkout-toggle-area p button,
.theme-footer-four .footer-list ul li a,
.our-service-sass .service-block .detail-button,
.our-feature-sass .down-arrow,
.our-feature-sass .single-feature-block .text-box .read-more,
.sass-our-pricing .single-pr-table .upgrade-button:before,
.sass-our-pricing .single-pr-table .upgrade-button,
.sass-our-pricing .single-pr-table .trial-button,
.sass-faq-section .sub-heading a,
.sass-faq-section .down-button,
.eCommerce-side-menu .main-menu-list > ul > li > a,
.theme-menu-one.d-align-item .navbar-nav .login-button-two a,
.our-service-app .main-content .more-button,
.our-service-app .main-content .down-arrow,
.our-feature-app .text-wrapper .explore-button,
.theme-Ecommerce-menu .right-content .cart-action-wrapper .button-group a,
.theme-Ecommerce-menu .right-content .user-profile-action .dropdown-menu ul li a,
#eCommerce-carousel .color-tab ul li a,
.eCommerce-side-menu .main-menu-list > ul .sub-menu a,
#eCommerce-carousel .inner-item-wrapper .button-group .shop-now,
#eCommerce-carousel .inner-item-wrapper .button-group .details-info-button,
.eCommerce-hero-section .social-share li a,
.eCommerce-hero-section .carousel-indicators li,
.product-details-modal .main-bg-wrapper .close-button,
.product-details-modal .main-bg-wrapper .next,
.product-details-modal .main-bg-wrapper .prev,
.theme-sidebar-widget .list-item li a,
.theme-sidebar-widget .size-filter li a,
.theme-sidebar-widget .color-filter li a,
.shop-demo-filter .selectize-dropdown .option,
.product-showcase .single-product-case,
.product-showcase .single-product-case .info .name,
.product-showcase .single-product-case .info .cart-button:before,
.theme-sidebar-widget .sidebar-search button,
.product-showcase .single-product-case .info .cart-button:after,
.product-showcase .single-product-case .info .cart-button span,
.theme-pagination-one ul li a,
.theme-sidebar-widget .list-item li .sub-menu a,
.cart-list-form .table .product-info .product-name,
.cart-list-form .table .remove-product,
.dark-button-one,
.checkout-form .checkbox-list li label:before,
.page-breadcrumbs li a,
.theme-footer-four .bottom-footer ul li a,
.theme-sidebar-widget .sidebar-popular-product .info a,
.shop-details .procuct-details .product-info .cart-button,
.shop-details .procuct-details .product-info .wishlist-button,
.navbar .mega-dropdown-list li a .icon,
.shop-details .product-review-tab .nav-tabs li a:before,
.faq-page .faq-search-form button,
.faq-page .submit-faq button,
.our-team .single-team-member .info-meta,
.our-blog .post-data .date,
.our-team .single-team-member .hover-content ul li a,
.our-team .single-team-member .hover-content,
body .theme-button-one,
body .theme-button-one:before,
.our-service .service-block .read-more,
.service-standard .service-block:before,
.theme-action-banner-two .banner-button,
#isotop-gallery-wrapper .isotop-item .hover-jojo ul li,
.gallery-sidebar .sidebar-list li a,
.our-blog .post-data .read-more,
#isotop-gallery-wrapper .isotop-item .hover-jojo ul li a,
#isotop-gallery-wrapper .isotop-item .hover-jojo,
.our-project .project-item .hover-valina,
.gallery-sidebar .sidebar-icon ul li a,
.theme-footer-two ul li a,
.project-with-sidebar .project-item .img-box a,
.project-minimal-style .share-icon li a,
.project-details .share-icon li a,
.pd-footer .theme-pager,
.pd-footer .theme-pager span,
body .theme-button-two:before,
.our-blog .img-holder .video-button,
.theme-sidebar-widget .keywords-tag li a,
.blog-details .post-tag-area .tags li a,
.blog-details .post-tag-area .share-icon li a,
.blog-details .user-comment-area .single-comment .reply,
.blog-details-fg .blog-fg-data .video-banner-blog .video-button,
#contact-form .form-group .help-block,
.contact-us-section .contact-info ul li a,
.form-style-three .send-button,
.faq-tab-wrapper-four .faq-panel .panel .panel-heading .panel-title a i,
.contact-address-two .address-block a,
body .line-button-one,
body .solid-button-one,
body .white-shdw-button,
#theme-banner-two .main-wrapper .button-group .contact-button,
#theme-banner-two .main-wrapper .button-group .more-button,
.shrt-menu .top-header .infoList li a,
.shrt-menu .top-header .right-widget .user-profile-action .dropdown-menu ul li a,
.shrt-menu .menu-social-icon li a,
.shrt-menu .main-header .cart-action-wrapper .button-group a,
#arch-carousel .indicators-one li i,
#arch-carousel .indicators-one li span,
.arch-footer .top-footer .footer-list ul li a,
.theme-tab .tabs-menu li a,
.studio-feature-area .feature-block .title,
.studio-feature-area .feature-block,
.studio-project-holder,
.studio-client-slider .customer-block p,
.studio-client-slider .customer-block .name,
.studio-client-slider .customer-block .customer-info span,
.studio-client-slider .customer-block:before,
.studio-client-slider .customer-block,
.studio-footer-one .top-footer .footer-list ul li a,
.studio-footer-one .bottom-footer ul li a {
  transition: all 0.3s ease-in-out;
}
.tran4s,
.theme-main-menu,
.our-service-portfo .service-block:before,
.our-service-portfo .service-block:after,
.our-project .project-item p,
.our-project-portfo #isotop-gallery-wrapper .isotop-item .effect-zoe img,
.our-team .img-box img,
.service-standard .service-block .hover-content p,
.our-project .project-item .hover-valina .zoom,
.our-project .project-item .hover-valina:before,
.our-project .project-item .hover-valina:after,
#mega-menu-holder .img-box .img img {
  transition: all 0.4s ease-in-out;
}
/*----------------------- Theme Color File -------------------*/
.theme-footer-one .top-footer .footer-list ul li a:hover,
.theme-footer-one .bottom-footer ul li a:hover,
.seo-our-goal .text-wrapper .request-button:hover,
body .video-button-one:hover,
body .video-button-one:hover i,
.seo-our-pricing .single-pr-table .pr-header .price,
.seo-our-pricing .single-pr-table .pr-footer .trial-button:hover,
.theme-menu-one .navbar-nav .nav-item:hover .nav-link,
body .white-shdw-button,
.theme-menu-one .navbar-nav .nav-item.active .nav-link,
.sass-faq-section .sub-heading a:hover,
.theme-Ecommerce-menu .right-content .user-profile-action .dropdown-menu ul li a:hover,
.eCommerce-side-menu .main-menu-list > ul > li:hover > a,
.eCommerce-side-menu .main-menu-list > ul .sub-menu a:hover,
.eCommerce-hero-section .social-share li a:hover,
.theme-counter-three .single-counter-box .number,
.product-details-modal .main-bg-wrapper .close-button:hover,
.theme-sidebar-widget .list-item li:hover a,
.product-showcase .single-product-case:hover .info .name,
.theme-title-one .upper-title,
.theme-pagination-one ul li a:hover,
.theme-pagination-one ul li.active a,
.theme-sidebar-widget .sidebar-search button:hover,
.theme-sidebar-widget .list-item li .sub-menu a:hover,
.agn-what-we-do .single-block .more-button,
.cart-list-form .table .product-info .product-name:hover,
.cart-list-form .table .remove-product:hover,
.checkout-toggle-area p button:hover,
.checkout-toggle-area form .lost-passw:hover,
.solid-inner-banner .page-breadcrumbs li a:hover,
.theme-sidebar-widget .sidebar-popular-product li:hover .info a,
.shop-details .procuct-details .product-info .price,
.faq-page .faq-search-form button:hover,
.shop-details .procuct-details .product-info .cart-button:hover,
.realated-product .owl-theme .owl-nav [class*='owl-']:hover,
.faq-tab-wrapper.faq-page .faq-panel .panel .panel-heading.active-panel .panel-title a:before,
.faq-page .submit-faq button:hover,
.team-standard .single-team-member .hover-content ul li a:hover,
body .theme-button-one,
.team-minimal .single-team-member .hover-content ul li a:hover,
.team-classic .wrapper .hover-content li a:hover,
.service-minimal .service-block:hover .read-more,
.service-modren .service-block .service-info:hover .read-more,
.gallery-sidebar .sidebar-icon ul li a:hover,
.service-standard .service-block .hover-content .read-more:hover,
.our-project .isotop-menu-wrapper li.is-checked,
.gallery-sidebar .sidebar-list li a:hover,
.gallery-sidebar .sidebar-list li.active a,
.project-with-sidebar .project-item .hover-coco .title a:hover,
.project-minimal-style .owl-theme .owl-nav [class*='owl-']:hover,
.pd-footer .theme-pager:hover,
.pd-footer .theme-pager:hover span,
.related-project .owl-theme .owl-nav [class*='owl-']:hover,
.our-blog .single-blog-post:hover .post-data .title a,
.theme-sidebar-widget .recent-news-item li:hover .rn-title a,
body .line-button-one,
.blog-details .post-tag-area .share-icon li a:hover,
#contact-form .form-group .help-block li:before,
.contact-address-two .address-block p a:hover,
.contact-address-two .address-block ul li a:hover,
body .solid-button-one:hover,
.navbar .navbar-toggler,
.faq-tab-wrapper .faq-panel .panel .panel-heading.active-panel .panel-title a:before,
.faq-tab-wrapper-two .faq-panel .panel .panel-heading.active-panel .panel-title a:before,
.faq-tab-wrapper-four .faq-panel .panel .panel-heading .panel-title a i,
.breadcrumbs-two .page-breadcrumbs li a:hover,
.b-wh-text .page-breadcrumbs li a:hover,
.agn-counter-section .counter-wrapper .single-counter-box .icon,
.agn-counter-section .counter-wrapper .single-counter-box .number,
.theme-counter-three .single-counter-box .icon,
.theme-list-item li .color-style,
.theme-menu-one .header-right-widget .call-us a,
.theme-footer-two .social-icon li a:hover,
.agn-our-pricing .table-wrapper .pr-column .pr-header .price,
.theme-footer-one .about-widget .phone,
.navbar .mega-dropdown-list li a:hover,
.theme-menu-one .header-right-widget .language-switcher .dropdown-menu ul li a:hover,
.theme-footer-three .phone,
.theme-footer-four .footer-list ul li a:hover,
.theme-footer-four .phone,
.theme-footer-four .bottom-footer ul li a:hover,
.theme-footer-four.light-v .footer-list ul li a:hover,
.theme-footer-four.light-v .bottom-footer ul li a:hover,
.shrt-menu .main-header .call-us a,
.shrt-menu .top-header .infoList li a:hover,
.shrt-menu .top-header .right-widget .language-switcher .dropdown-menu ul li a:hover,
.shrt-menu .top-header .right-widget .user-profile-action .dropdown-menu ul li a:hover,
.shrt-menu.text-light .navbar .dropdown-menu .dropdown-item:hover,
.shrt-menu.text-light .navbar .mega-dropdown-list li a:hover,
.shrt-menu.text-light .top-header .infoList li a:hover,
.shrt-menu.dark-bg .top-header .right-widget .language-switcher .dropdown-menu ul li a:hover,
.shrt-menu.dark-bg .top-header .right-widget .user-profile-action .dropdown-menu ul li a:hover,
.navbar .mega-dropdown-list li a:hover .icon,
.pricing-tab-menu .nav-tabs .nav-item .nav-link,
.signin-form-wrapper .signUp-text a,
#arch-carousel .inner-item-wrapper .upper-title,
#arch-carousel .indicators-one li i,
.arch-project-gallery .isotop-menu-wrapper li.is-checked,
.arch-service .single-block:hover .title a,
.arch-blog .blog-post:hover .title a,
.arch-contact .form-wrapper .contact-text .call-us,
.arch-footer .top-footer h2 a,
.arch-footer .top-footer .phone,
.theme-footer-one.arch-footer .top-footer .footer-list ul li a:hover,
.theme-tab-basic.theme-tab .tabs-menu li.z-active a,
.theme-tab .z-content-inner ol li a:hover,
.studio-feature-area .feature-block:hover .title,
.z-tabs.mobile > ul.z-tabs-mobile > li > a > span.drp-icon,
#studio-banner-two .main-text-wrapper .button-link,
.studio-feature-area .feature-block .icon-s,
.studio-feature-area .feature-block .detail-button,
.studio-client-slider .customer-block:before,
.studio-contact-area .contact-text .call-to-dial,
.studio-footer-one .top-footer h2 a,
.studio-footer-one .top-footer .footer-list ul li a:hover {
  color: #ff3a46;
}
.scroll-top,
.theme-footer-one .top-footer .footer-information ul li a:hover,
.theme-menu-two .quote-button:hover,
.theme-Ecommerce-menu .right-content .cart-action-wrapper .button-group a:hover,
#eCommerce-carousel .inner-item-wrapper .button-group .shop-now:hover,
#eCommerce-carousel .inner-item-wrapper .button-group .details-info-button:hover,
.product-details-modal .main-bg-wrapper .cart-button,
.theme-sidebar-widget .price-ranger .ui-slider .ui-slider-range,
.theme-sidebar-widget .size-filter li a:hover,
.shop-demo-filter .selectize-dropdown .option:hover,
.shop-demo-filter .selectize-dropdown .active,
.product-showcase .single-product-case .info .cart-button span,
.dark-button-one:hover,
.shop-details .procuct-details .product-info .cart-button,
.shop-details .procuct-details .product-info .wishlist-button:hover,
.shop-details .product-review-tab .tab-content .list-item li:before,
.faq-page .submit-faq button,
body .theme-button-one:before,
.newsletter-section.agn-theme .main-wrapper form button,
.newsletter-section.agn-theme .theme-title-one.upper-bar:before,
.video-action-banner-one .video-button,
.video-action-banner-one .video-button:before,
.theme-action-banner-two .banner-button:hover,
#isotop-gallery-wrapper .isotop-item .hover-jojo ul li a:hover,
.gallery-sidebar .sidebar-title:before,
.project-details .side-block .block-title:before,
.our-blog .single-blog-post:hover .img-holder .video-button,
.theme-sidebar-widget .keywords-tag li a:hover,
.blog-details .user-comment-area .single-comment .reply:hover,
body .theme-button-two,
.blog-details-fg .blog-fg-data .video-banner-blog .video-button:hover,
#contact-form .form-group .help-block,
.contact-us-section .contact-info ul li a:hover,
.form-style-three .send-button:hover,
body .line-button-one:hover,
body .solid-button-one,
body .line-button-two:hover,
.agn-our-pricing .table-wrapper .pr-column:hover .line-button-two,
body .white-shdw-button:hover,
#theme-banner-two .main-wrapper .button-group .contact-button:hover,
.seo-what-we-do .single-block:nth-child(1) .wrapper .icon-box,
.faq-tab-wrapper-three .faq-panel .panel .panel-heading.active-panel .panel-title a,
.faq-tab-wrapper-four .faq-panel .panel .panel-heading.active-panel .panel-title a,
.agn-our-gallery .main-wrapper .view-gallery:hover,
.agn-home-blog .single-blog-post .flip-box-back,
.seo-contact-banner .contact-button:hover,
.navbar .dropdown-menu .dropdown-item span,
.ln-inner-page-demo .inner-wrapper .single-page a .new,
.theme-footer-three .social-icon li a:hover,
.theme-footer-four .social-icon li a:hover,
.navbar .mega-dropdown-list li a span,
.btn-white:hover,
.ln-home-demo .single-page-demo .new,
.shrt-menu .main-header .cart-action-wrapper .button-group a:hover,
.pricing-tab-menu .nav-tabs .nav-item .nav-link.active,
#arch-carousel .indicators-one li.active span,
.about-arch .text-wrapper .mark-text:before,
.about-arch .img-box .sq-box,
body .line-button-three:hover,
.arch-testimonial .owl-theme .owl-dots .owl-dot.active span,
.arch-testimonial .owl-theme .owl-dots .owl-dot span:hover,
#arch-carousel .details-info-button,
.theme-tab .tabs-menu li a:before,
.theme-tab .z-content-inner .list-item li:before,
.theme-tab-solid.theme-tab .tabs-menu li.z-active a,
.theme-tab .nested-tab-menu li.z-active a:before,
.action-banner-three,
.studio-client-slider .center .customer-block {
  background: #000000;
}
.theme-footer-one .top-footer .footer-information ul li a:hover,
.seo-contact-banner .contact-button:hover,
.theme-Ecommerce-menu .right-content .cart-action-wrapper .button-group a:hover,
body .theme-button-one,
.theme-sidebar-widget .size-filter li a:hover,
.shop-details .procuct-details .product-info .cart-button,
.shop-details .procuct-details .product-info .wishlist-button:hover,
.faq-page .submit-faq button,
.theme-action-banner-two .banner-button:hover,
.our-project .isotop-menu-wrapper li.is-checked,
#isotop-gallery-wrapper .isotop-item .hover-jojo ul li a:hover,
.theme-sidebar-widget .keywords-tag li a:hover,
.blog-details .user-comment-area .single-comment .reply:hover,
.contact-us-section .contact-info ul li a:hover,
.form-style-three .send-button:hover,
body .line-button-one,
body .solid-button-one,
body .line-button-two:hover,
.agn-our-pricing .table-wrapper .pr-column:hover .line-button-two,
#theme-banner-two .main-wrapper .button-group .contact-button:hover,
.agn-our-gallery .main-wrapper .view-gallery:hover,
.theme-footer-three .social-icon li a:hover,
.theme-footer-four .social-icon li a:hover,
.pricing-tab-menu .nav-wrapper,
body .line-button-three:hover,
.arch-testimonial .owl-theme .owl-dots .owl-dot span,
.studio-feature-area .feature-block:hover {
  border-color: #ff3a46;
}

/*------------------- Theme Title ------------------*/
.arch-title .main-title {
  font-family: 'CircularStdbld';
}
.arch-title .line {
  display: inline-block;
  position: relative;
}
.arch-title .line:before {
  content: '';
  width: 0;
  height: 3px;
  background: #233d63;
  border-radius: 5px;
  position: absolute;
  right: -175px;
  bottom: 10px;
  transition: all 0.8s ease-out;
}
.arch-title.show-pr .line:before {
  width: 170px;
}
.theme-title-one,
.theme-title-two {
  position: relative;
}
.theme-title-one .upper-title {
  font-size: 18px;
  text-transform: uppercase;
  padding-bottom: 15px;
}
.theme-title-one .main-title {
  font-size: 48px;
  line-height: 60px;
  color: #233d63;
}
.theme-title-one.studio-title .main-title {
  font-size: 72px;
  font-weight: 600;
  line-height: 75px;
  color: #202020;
}
.theme-title-one.title-underline .main-title {
  text-decoration: underline;
}
.theme-title-one .bottom-title {
  font-size: 20px;
  color: #546886;
  padding-top: 20px;
}
.theme-title-one.upper-bar {
  padding-top: 22px;
}
.theme-title-one.upper-bar:before {
  content: '';
  width: 50px;
  height: 4px;
  border-radius: 5px;
  background: #ff7300;
  position: absolute;
  top: 0;
  left: 0;
}
.theme-title-one.upper-bar.text-center:before {
  left: 50%;
  margin-left: -25px;
}
.theme-title-one .icon-box {
  display: inline-block;
  margin-bottom: 25px;
  position: relative;
}
.theme-title-one .icon-box .bg-shape {
  transform: rotate(-90deg);
  transition: all 1s ease-out;
}
.theme-title-one .icon-box.show-pr .bg-shape {
  transform: rotate(0deg);
}
.theme-title-one .icon-box .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.theme-title-one.arch-title .upper-title {
  color: #aeaeae;
}
.theme-title-two {
  position: relative;
  font-size: 144px;
  color: #fff;
}
.theme-title-two:first-letter {
  text-transform: uppercase;
  font-size: 300px;
  text-shadow: 2px -0.9px 0px rgba(225, 163, 76, 1);
  color: #1d2046;
  padding-right: 15px;
}
.theme-title-two span {
  color: #e1a34c;
}
.theme-title-three {
  padding: 0 145px;
}
.theme-title-three .title {
  font-size: 48px;
  line-height: 60px;
  color: #3e3e3e;
}
.theme-title-three p {
  font-size: 24px;
  line-height: 40px;
  color: #798598;
  padding-top: 45px;
}
.theme-title-four {
  font-size: 72px;
  line-height: 85px;
  color: #202020;
  font-weight: 600;
}
.theme-title-four span {
  position: relative;
  display: inline-block;
}
.theme-title-four span:before {
  content: '';
  width: 100%;
  height: 76%;
  position: absolute;
  top: 20px;
  left: 0;
  background: rgba(255, 221, 79, 0.3);
  z-index: -1;
}
.theme-title-four span:after {
  content: '';
  width: 120px;
  height: 3px;
  position: absolute;
  background: #202020;
  top: 55%;
  right: -150px;
}
/*------------------- Theme Pagination ------------------*/
.theme-pagination-one ul {
  display: inline-block;
  margin: 0 -10px;
}
.theme-pagination-one ul li {
  float: left;
  padding: 0 10px;
}
.theme-pagination-one ul li a {
  font-size: 24px;
  color: #4f4f4f;
  line-height: 40px;
}
.theme-pagination-one ul li a .icon {
  font-size: 30px;
}
.theme-pagination-one ul li.active a {
  cursor: default;
}
/*----------------------- Theme Main Menu --------------------*/
#mega-menu-holder {
  position: static;
}
#mega-menu-holder .img-box {
  display: block;
  text-align: center;
}
#mega-menu-holder .img-box .img {
  display: block;
  overflow: hidden;
  background: #000;
  box-shadow: 0px 30px 70px 0px rgba(223, 227, 234, 0.5);
}
#mega-menu-holder .img-box .img img {
  width: 100%;
}
#mega-menu-holder .img-box:hover .img img {
  opacity: 0.7;
  transform: scale3d(1.1, 1.1, 1);
}
#mega-menu-holder .img-box .text {
  font-size: 15px;
  letter-spacing: 1px;
  display: block;
  color: #212121;
  padding-top: 10px;
}
.theme-main-menu {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 99;
  padding: 65px 0 0;
}
.theme-menu-one .logo {
  position: absolute;
  top: -1px;
  left: 0;
  padding: 60px 0 0 110px;
  z-index: 3;
  max-width: 250px;
}
.theme-menu-one .logo a {
  display: block;
}
.theme-menu-one .navbar-nav .nav-item .nav-link {
  color: #64697a;
}
.theme-menu-one .navbar-nav .nav-item:first-child .nav-link {
  margin-left: 0;
}
.theme-menu-one .header-right-widget {
  position: absolute;
  top: 52px;
  right: 60px;
  z-index: 1;
}
.theme-menu-one .header-right-widget > ul > li {
  display: inline-block;
  vertical-align: middle;
}

.theme-menu-one .header-right-widget .language-switcher button {
  background: transparent;
  height: 55px;
  text-transform: uppercase;
  color: #233d63;
  font-size: 16px;
  margin: 0 45px 0 40px;
}
.theme-menu-one .header-right-widget .language-switcher .dropdown-menu {
  min-width: 170px;
  padding: 10px 0;
  background: #fff;
  box-shadow: 0px 25px 50px 0px rgba(213, 216, 223, 0.5);
  border: 1px solid #f8f8f8;
  border-radius: 0;
}
.theme-menu-one .header-right-widget .language-switcher .dropdown-menu ul li a {
  display: block;
  padding: 0 15px;
  font-size: 15px;
  color: #767a89;
  line-height: 35px;
  text-transform: uppercase;
}
.skiptranslate {
  display: none !important;
}
.theme-menu-one .header-right-widget .language-switcher .dropdown-menu ul li a:hover {
  padding-left: 20px;
}
.theme-menu-one .header-right-widget .language-switcher .dropdown-toggle::after {
  color: #233d63;
}
.theme-menu-one .header-right-widget .call-us {
  font-size: 20px;
  color: #a2adbd;
}
.theme-menu-one .header-right-widget .call-us a {
  font-size: 24px;
  margin-left: 15px;
}
/*----------------- SASS MENU ---------------------*/
.theme-menu-one.d-align-item .navbar-nav .nav-item:hover .nav-link,
.theme-menu-one.d-align-item .navbar-nav .nav-item.active .nav-link,
.theme-menu-one.d-align-item .navbar .dropdown-item:focus,
.theme-menu-one.d-align-item .navbar .dropdown-item:hover,
.theme-menu-one.d-align-item .navbar .dropdown-submenu.dropdown:hover > .dropdown-item,
.theme-menu-one.d-align-item .navbar .navbar-toggler,
.theme-menu-one.d-align-item .navbar .mega-dropdown-list li a:hover,
.theme-menu-one.d-align-item .navbar .mega-dropdown-list li a:hover .icon {
  color: #f569f8;
}
.theme-menu-one.d-align-item .navbar .mega-dropdown-list li a:hover .svg path {
  fill: #f569f8;
}
.theme-menu-one.d-align-item .navbar-nav .nav-item .nav-link {
  padding-bottom: 0;
}
.theme-menu-one.d-align-item .navbar-nav .nav-item .nav-link:before {
  content: '';
  width: 0;
  height: 2px;
  position: absolute;
  left: 1px;
  bottom: 5px;
  z-index: -1;
  background: #f569f8;
  transition: width 0.3s ease-out;
}
.theme-menu-one.d-align-item .header-right-widget .call-us a {
  color: #bebebe;
  margin-left: 0;
}
.theme-menu-one.d-align-item .header-right-widget .call-us a:hover {
  color: #f569f8;
}
.theme-menu-one.d-align-item .navbar-nav .nav-item .nav-link:hover:before,
.theme-menu-one.d-align-item .navbar-nav .nav-item.active .nav-link:before {
  width: 100%;
}
.theme-menu-one.d-align-item #mega-menu-holder {
  margin-right: 125px;
}
.theme-menu-one.d-align-item .header-right-widget {
  right: auto;
  left: 15%;
}
.theme-menu-one.d-align-item .logo {
  top: 45px;
  left: 75px;
  width: 63px;
}
.theme-menu-one.d-align-item .navbar-nav .login-button a {
  width: 150px;
  line-height: 50px;
  background: #fef9da;
  border-radius: 3px;
  font-size: 18px;
  color: #ead24c;
  text-align: center;
  margin-top: -12px;
}
.theme-menu-one.d-align-item .navbar-nav .login-button a:hover {
  background: #f569f8;
  color: #fff;
}
.theme-menu-one.d-align-item .navbar-nav .login-button-two a {
  width: 150px;
  line-height: 48px;
  border: 1px solid #ffffff;
  border-radius: 3px;
  font-size: 18px;
  color: #fff;
  text-align: center;
  margin-top: -12px;
}
.theme-menu-one.d-align-item .navbar-nav .login-button-two a:hover {
  background: #fff;
  color: #614ef9;
}
/*------------------ Menu One Color Fix -------------------*/
.theme-menu-one.d-align-item.color-white .header-right-widget .call-us a,
.theme-menu-one.d-align-item.color-white .header-right-widget .language-switcher button,
.theme-menu-one.d-align-item.color-white
  .header-right-widget
  .language-switcher
  .dropdown-toggle::after,
.theme-menu-one.d-align-item.color-white .navbar-nav .nav-item .nav-link {
  color: #fff;
}
.theme-menu-one.d-align-item.color-white .navbar-nav .nav-item .nav-link:before {
  background: #fff;
}
/*----------------------- Theme Main Menu Two  --------------------*/
.theme-menu-two {
  padding-left: 90px;
  padding-right: 90px;
}
.theme-menu-two .logo {
  position: absolute;
  top: 50px;
  left: 90px;
  width: 63px;
  z-index: 2;
}
.theme-menu-two .quote-button {
  width: 160px;
  line-height: 50px;
  border-radius: 3px;
  background: #fff;
  font-size: 18px;
  color: #3d3d3d;
  text-align: center;
  position: absolute;
  right: 90px;
  top: 55px;
  z-index: 1;
}
.theme-menu-two .quote-button:hover {
  color: #fff;
}
.theme-menu-two #mega-menu-holder {
  margin-right: 265px;
}
/*---------------------- Theme Main Menu Three --------------------*/
.theme-menu-three .logo {
  position: absolute;
  top: -1px;
  left: 0;
  z-index: 3;
}
.theme-menu-three .logo a {
  display: block;
}
.theme-menu-three .navbar-nav .nav-item {
  padding-bottom: 15px;
}
.theme-menu-three .navbar-nav .nav-item .nav-link {
  color: #fff;
  position: relative;
  padding: 0;
}
.theme-menu-three .navbar-nav .nav-item .nav-link:before {
  content: '';
  width: 0;
  height: 5px;
  position: absolute;
  left: 1px;
  bottom: 7px;
  z-index: -1;
  background: #e1a34c;
  transition: width 0.3s ease-out;
}
.theme-menu-three .navbar-nav .nav-item .nav-link:hover:before,
.theme-menu-three .navbar-nav .nav-item.active .nav-link:before {
  width: 91%;
}
.theme-menu-three #mega-menu-holder {
  margin-right: 255px;
}
.theme-menu-three .header-right-widget {
  position: absolute;
  top: 57px;
  right: 65px;
  z-index: 1;
}
.theme-menu-three .header-right-widget .social-icon li {
  display: inline-block;
}
.theme-menu-three .header-right-widget .social-icon li a {
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  border: 2px solid rgba(255, 255, 255, 0.8);
  font-size: 20px;
  color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  margin-left: 5px;
}
.theme-menu-three .header-right-widget .social-icon li a:hover {
  color: #fff;
  background: #cd9a53;
  border-color: #cd9a53;
}
.theme-menu-three .navbar .dropdown-item:focus,
.theme-menu-three .navbar .dropdown-item:hover,
.theme-menu-three .navbar .dropdown-submenu.dropdown:hover > .dropdown-item,
.theme-menu-three .navbar .mega-dropdown-list li a:hover,
.theme-menu-three .navbar .mega-dropdown-list li a:hover .icon {
  color: #e1a34c;
}
.theme-menu-three .navbar .mega-dropdown-list li a:hover .svg path {
  fill: #e1a34c;
}
/*---------------------- APP Menu ----------------------*/
.theme-menu-one.d-align-item.app-menu .navbar .dropdown-item:focus,
.theme-menu-one.d-align-item.app-menu .navbar .dropdown-item:hover,
.theme-menu-one.d-align-item.app-menu .navbar .dropdown-submenu.dropdown:hover > .dropdown-item,
.theme-menu-one.d-align-item.app-menu .navbar .mega-dropdown-list li a:hover,
.theme-menu-one.d-align-item.app-menu .navbar .mega-dropdown-list li a:hover .icon {
  color: #614ef9;
}
.theme-menu-one.d-align-item.app-menu .navbar .mega-dropdown-list li a:hover .svg path {
  fill: #614ef9;
}
/*================= Sticky Menu ===============*/
@-webkit-keyframes menu_sticky {
  0% {
    margin-top: -120px;
    opacity: 0;
  }
  50% {
    margin-top: -64px;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}
@keyframes menu_sticky {
  0% {
    margin-top: -120px;
    opacity: 0;
  }
  50% {
    margin-top: -64px;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}
.theme-main-menu.fixed {
  position: fixed !important;
  background: #fff;
  box-shadow: 0px 13px 35px -12px rgba(35, 35, 35, 0.15);
  animation: menu_sticky 0.6s ease-in-out;
}
.theme-menu-one.d-align-item.fixed .logo {
  width: 63px;
}
.theme-menu-one.fixed.d-align-item .logo {
  top: 5px;
}
.theme-menu-one.fixed .navbar-nav .nav-item .nav-link,
.theme-menu-two.fixed .navbar-nav .nav-item .nav-link {
  padding-bottom: 20px;
}

.theme-menu-one.fixed,
.theme-menu-two.fixed {
  padding: 20px 0 0;
}
.theme-main-menu.theme-menu-three.fixed {
  padding: 20px 0 5px;
}
.theme-menu-one.fixed .logo,
.theme-menu-three.fixed .logo {
  width: 70px;
}
.theme-menu-one.fixed .header-right-widget {
  top: 7px;
}
.theme-menu-two.fixed .logo {
  top: 7px;
}
.theme-menu-two.fixed .quote-button {
  top: 9px;
}
.theme-main-menu.theme-menu-two.fixed,
.theme-menu-one.d-align-item.color-white.fixed {
  background: #1b1d3d;
}
.theme-main-menu.theme-menu-three.fixed {
  background: rgba(29, 31, 66, 0.98);
  border-bottom: 1px solid rgba(255, 255, 255, 0.03);
}
.theme-menu-three.fixed .header-right-widget {
  top: 14px;
}
.theme-menu-one.d-align-item.fixed .navbar-nav .nav-item .nav-link {
  padding-bottom: 0;
}
.theme-menu-one.d-align-item.fixed {
  padding-bottom: 12px;
}
/*---------------------------- Theme Main Banner One -------------------------*/
#theme-banner-one {
  background: url(../images/shape/1.png) no-repeat top right;
  position: relative;
}
#theme-banner-one .main-wrapper {
  padding: 235px 0 155px;
}
#theme-banner-one .main-wrapper .slogan {
  display: inline-block;
  background: #fbfbff;
  border: 1px solid #e9e9e9;
  line-height: 32px;
  padding: 0 20px;
  font-size: 15px;
  color: #677180;
  border-radius: 3px;
}
#theme-banner-one .main-wrapper .slogan span {
  color: #ff605e;
}
#theme-banner-one .main-wrapper .main-title {
  font-family: 'CircularStdmed';
  font-size: 70px;
  line-height: 80px;
  padding: 25px 0 35px;
}
#theme-banner-one .main-wrapper .sub-title {
  font-size: 20px;
  line-height: 32px;
  color: #8b8e92;
  padding-bottom: 28px;
}
#theme-banner-one .main-wrapper .button-group li {
  display: inline-block;
  vertical-align: middle;
  margin-top: 5px;
  line-height: 55px;
}
#theme-banner-one .main-wrapper .button-group .more-button {
  margin-right: 30px;
}
#theme-banner-one .illustration {
  position: absolute;
  right: 12%;
  top: 27%;
}
#theme-banner-one .illustration .shape-one {
  position: absolute;
  top: -28px;
  right: -63px;
}
#theme-banner-one .illustration .shape-two {
  position: absolute;
  bottom: 0;
  right: -25px;
}
#theme-banner-one .oval-one {
  position: absolute;
  right: -3%;
  top: 26%;
  animation: jump 8s infinite linear;
}
#theme-banner-one .shape-three {
  position: absolute;
  top: 21%;
  right: 22%;
  animation: animationFramesTwo 13s infinite linear;
}
#theme-banner-one .shape-four {
  position: absolute;
  top: 27%;
  left: 7%;
  animation: animationFramesFour 25s alternate infinite linear;
}
#theme-banner-one .shape-five {
  position: absolute;
  top: 50%;
  left: 50%;
  animation: animationFramesThree 35s alternate infinite linear;
}
#theme-banner-one .shape-six {
  position: absolute;
  top: 70%;
  right: 3%;
  animation: animationFramesFour 20s alternate infinite linear;
}
#theme-banner-one .shape-seven {
  position: absolute;
  bottom: 2%;
  right: 28%;
  animation: animationFramesOne 15s infinite linear;
}
#theme-banner-one .shape-eight {
  position: absolute;
  bottom: 3%;
  left: 38%;
  animation: animationFramesFour 20s alternate infinite linear;
}
#theme-banner-one .shape-nine {
  position: absolute;
  bottom: 4%;
  left: 4%;
  animation: animationFramesOne 17s infinite linear;
}
#theme-banner-one .shape-ten {
  position: absolute;
  top: 60%;
  left: 4%;
  animation: animationFramesOne 20s alternate infinite linear;
}
#theme-banner-one .shape-eleven {
  position: absolute;
  top: 22%;
  left: 41%;
  animation: animationFramesOne 15s infinite linear;
}

/*---------------------- E-Commerce Home Page ------------------------*/
#eCommerce-home {
  height: 100vh;
  position: relative;
}
#eCommerce-home:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #f0f0f0;
  left: 50%;
  z-index: -1;
}
#eCommerce-home .main-page-wrapper {
  height: 100%;
  position: relative;
}
#eCommerce-home .main-page-wrapper:before,
#eCommerce-home .main-page-wrapper:after {
  content: '';
  position: absolute;
  height: 100%;
  top: 0;
  width: 16.666666666%;
  border-left: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  z-index: -1;
}
#eCommerce-home .main-page-wrapper:before {
  left: 16.6666666%;
}
#eCommerce-home .main-page-wrapper:after {
  right: 16.6666666%;
}
/*---------------------- Sidebar Menu ------------------------*/
.eCommerce-side-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 360px;
  background: #fff;
  box-shadow: 0px 2px 100px 0px rgba(218, 218, 218, 0.5);
  z-index: 999;
  padding: 0 25px 30px 80px;
  opacity: 0;
  transform: scale(0, 1);
  transform-origin: 0% 0%;
  transition: all 0.5s ease-in-out;
  max-height: 100vh;
  overflow-y: auto;
}
.eCommerce-side-menu.show-menu {
  opacity: 1;
  transform: scale(1);
}
.eCommerce-side-menu .logo-wrapper {
  margin-top: 60px;
  position: relative;
  padding-left: 30px;
}
.eCommerce-side-menu .logo-wrapper .close-button {
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  height: 36px;
}
.eCommerce-side-menu .main-menu-list > ul {
  margin-top: 70%;
  padding-bottom: 30px;
}
.eCommerce-side-menu .main-menu-list > ul > li > a {
  font-size: 36px;
  line-height: 60px;
  color: #3e3e3e;
  position: relative;
  display: block;
}
.eCommerce-side-menu .main-menu-list > ul > li {
  opacity: 0;
  transform: translateX(-50%);
  transition: all 0.7s ease-in-out;
}
.eCommerce-side-menu.show-menu .main-menu-list > ul > li:nth-child(1) {
  transition-delay: 0.2s;
}
.eCommerce-side-menu.show-menu .main-menu-list > ul > li:nth-child(2) {
  transition-delay: 0.3s;
}
.eCommerce-side-menu.show-menu .main-menu-list > ul > li:nth-child(3) {
  transition-delay: 0.4s;
}
.eCommerce-side-menu.show-menu .main-menu-list > ul > li:nth-child(4) {
  transition-delay: 0.5s;
}
.eCommerce-side-menu.show-menu .main-menu-list > ul > li:nth-child(5) {
  transition-delay: 0.6s;
}
.eCommerce-side-menu.show-menu .main-menu-list > ul > li {
  opacity: 1;
  transform: translateX(0);
}
.eCommerce-side-menu .main-menu-list > ul > li > a .expander {
  position: absolute;
  right: 0;
  top: 0;
  background: transparent;
  font-size: 15px;
  line-height: 60px;
  color: inherit;
}
.eCommerce-side-menu .main-menu-list > ul .sub-menu {
  padding: 10px 0 10px 30px;
}
.eCommerce-side-menu .main-menu-list > ul .sub-menu a {
  font-size: 24px;
  line-height: 35px;
  color: #b7b7b7;
}
.eCommerce-side-menu .copy-right {
  font-size: 16px;
  color: rgba(62, 62, 62, 0.7);
  margin-top: 100px;
}
.main-menu-list ul .sub-menu {
  display: none;
  transition: all 0.3s ease-in-out;
}
.main-menu-list ul .sub-menu.show {
  display: block;
}
.eCommerce-side-menu .eCommerce-search {
  width: 100%;
  margin: 40px 0;
  display: none;
}

/*-------------------- Error Page --------------------*/
.page-404 {
  height: 100vh;
  position: relative;
}
.page-404 .main-page-wrapper,
.error-content {
  height: 100%;
  position: relative;
}
.error-content:before {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  width: 40%;
  height: 100%;
  background: url(../images/home/404.png) no-repeat;
  background-size: cover;
}
.error-content .inner-wrapper {
  width: 60%;
  text-align: center;
  padding: 95px 0 50px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.error-content .inner-wrapper h2 {
  font-size: 200px;
  color: #313131;
}
.error-content .inner-wrapper p {
  font-size: 25px;
  line-height: 42px;
  margin: -30px 0 50px;
}
.error-creative-content {
  background: url(../images/home/error-bg.svg) no-repeat center;
  background-size: cover;
  height: 100%;
}
.error-creative-content .inner-wrapper {
  text-align: center;
  padding: 35px 0 50px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.error-creative-content .inner-wrapper h2 {
  font-size: 70px;
  color: #302c48;
}
.error-creative-content .inner-wrapper p {
  font-size: 24px;
  line-height: 35px;
  color: rgba(62, 62, 62, 0.55);
  margin: 35px 0 45px;
}
.error-creative-content .inner-wrapper .solid-button-one:hover {
  background: transparent;
}

/*------------------- Breadcrumbs ----------------*/
.breadcrumbs-two {
  background: #fafcff;
  padding: 265px 0 150px;
}
.breadcrumbs-two .page-title {
  font-family: 'CircularStdmed';
  font-size: 55px;
  color: #313131;
}
.breadcrumbs-two .page-breadcrumbs li {
  display: inline-block;
  font-size: 22px;
  color: #244574;
  margin: 0 0 0 3px;
}
.breadcrumbs-two .page-breadcrumbs li a {
  color: #244574;
}

.breadcrumbs-three {
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  padding: 265px 0 150px;
  position: relative;
  z-index: 1;
}
.breadcrumbs-three .page-title {
  font-family: 'CircularStdmed';
  font-size: 60px;
  padding-bottom: 15px;
}
.breadcrumbs-three .page-breadcrumbs li {
  display: inline-block;
  font-size: 22px;
  margin: 0 3px;
}
.b-wh-text .page-title,
.b-wh-text .page-breadcrumbs li a,
.b-wh-text .page-breadcrumbs li {
  color: #fff;
}
.breadcrumbs-four {
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  padding: 265px 0 150px;
  position: relative;
  z-index: 1;
}
.breadcrumbs-four .page-title {
  font-family: 'CircularStdmed';
  font-size: 55px;
}
.breadcrumbs-four .page-breadcrumbs li {
  display: inline-block;
  font-size: 22px;
  margin: 0 0 0 3px;
}
/*------------------- Counter ------------------*/
.theme-counter-three .single-counter-box {
  text-align: center;
  background: #fff;
  box-shadow: 0px 10px 25px 0px rgba(123, 147, 171, 0.15);
  padding: 40px 5px 50px;
  border-radius: 5px;
  margin-bottom: 40px;
}
.theme-counter-three .single-counter-box .icon {
  font-size: 45px;
  padding-bottom: 10px;
}
.theme-counter-three .single-counter-box .number {
  font-size: 60px;
}
.theme-counter-three .single-counter-box p {
  font-size: 20px;
}
/*-------------------- List Item -------------------*/
.theme-list-item li {
  font-family: 'Rubik', sans-serif;
  line-height: 44px;
  color: #777777;
  position: relative;
  padding-left: 22px;
}
.theme-list-item li i,
.theme-list-item li span {
  position: absolute;
  line-height: 44px;
  left: 0;
  top: 0;
  color: #313131;
  font-size: 15px;
}

/*-----------------------------------
            STUDIO PORTFOLIO ONE                 
--------------------------------------*/
/*-------------- Header/Menu ------------*/
.eCommerce-side-menu.op-page-menu-one {
  left: auto;
  right: 0;
  transform-origin: 100% 0%;
}
.one-page-header-one {
  position: absolute;
  top: 0;
  left: 0;
  padding: 60px 0 0 110px;
  z-index: 5;
  width: 250px;
}
.logo {
  width: 100%;
}
.logo img {
  width: 100%;
}
.op-menu-one {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  background: #fff;
  border-left: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  width: 140px;
  z-index: 99;
  text-align: center;
  padding-top: 55px;
}
.op-menu-one .op-menu-button {
  display: inline-block;
}
.op-menu-one .ct-language__dropdown li {
  display: inline-block;
  font-family: 'K2D', sans-serif;
  font-size: 24px;
}
.op-menu-one .ct-language__dropdown li a {
  color: #b3b3b3;
}
html[lang='en'] .op-menu-one .ct-language__dropdown li a[data-lang='en'],
html[lang='ru'] .op-menu-one .ct-language__dropdown li a[data-lang='ru'] {
  color: #202020;
}
.op-menu-one .ct-language__dropdown {
  transform: rotate(90deg) translateX(-50%);
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
}
.op-menu-one .social-icon {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 30px;
}
.op-menu-one .social-icon li a {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.18);
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
}
.op-menu-one .social-icon li a:hover {
  color: #3a3a3a;
}
/*---------------- Banner ---------------------*/
.op-banner-one {
  position: relative;
  padding: 260px 12% 0;
}
.op-banner-one .main-title {
  font-size: 100px;
  font-weight: 500;
  line-height: 105px;
  color: #161616;
}
.op-banner-one .main-title span {
  position: relative;
}
.op-banner-one .main-title span:before {
  content: '';
  width: 100%;
  height: 57%;
  position: absolute;
  top: 39px;
  left: 0;
  background: rgba(255, 221, 79, 0.3);
  z-index: -1;
}
.op-banner-one .banner-form {
  width: 465px;
  background: #fff;
  position: relative;
  z-index: 1;
  margin-left: auto;
  box-shadow: 0px 10px 19px 12px rgba(35, 35, 35, 0.03);
}
.op-banner-one .banner-form {
  margin-bottom: -109px;
  margin-top: 20px;
}
.op-banner-one .banner-form .input-group-wrapper {
  padding: 75px 70px 25px;
}
.op-banner-one .banner-form .input-group-wrapper input,
.op-banner-one .banner-form .input-group-wrapper textarea {
  width: 100%;
  border: none;
  border-bottom: 1px solid #3b3b3b;
  margin-bottom: 50px;
  font-size: 18px;
}
.op-banner-one .banner-form .input-group-wrapper input {
  height: 50px;
}
.op-banner-one .banner-form .input-group-wrapper textarea {
  height: 130px;
  max-height: 130px;
  resize: none;
}
.op-banner-one .banner-form form ::placeholder {
  color: #2c2c2c;
  opacity: 1; /* Firefox */
}
.op-banner-one .banner-form form :-ms-input-placeholder {
  color: #2c2c2c;
}
.op-banner-one .banner-form form ::-ms-input-placeholder {
  color: #2c2c2c;
}
.op-banner-one .banner-form form button {
  width: 100%;
  height: 110px;
  font-size: 22px;
  color: #fff;
  background: #1c1c1c;
  text-transform: uppercase;
}
.op-banner-one .credit-tag {
  position: absolute;
  top: 360px;
  left: -24px;
  text-align: right;
  transform: rotate(-90deg);
}
.op-banner-one .credit-tag:before {
  content: '';
  width: 2px;
  height: 36px;
  position: absolute;
  top: 12px;
  right: -22px;
  background: #212121;
}
.op-banner-one .credit-tag span {
  text-transform: uppercase;
  color: #202020;
}
/*---------------- One Page Hero Area ----------------*/
#op-hero-one {
  height: 940px;
  position: relative;
  overflow: hidden;
}
#op-hero-one .carousel-item,
#op-hero-one .carousel-inner {
  height: 100%;
}
#op-hero-one .carousel-item {
  background-size: cover;
}
#op-hero-one .carousel-nav {
  position: absolute;
  width: 140px;
  height: 100%;
  background: #fff;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  top: 0;
  right: 0;
  z-index: 1;
}
#op-hero-one .carousel-control-next,
#op-hero-one .carousel-control-prev {
  position: static;
  display: block;
  font-size: 18px;
  color: rgba(61, 63, 66, 0.28);
  text-transform: uppercase;
  opacity: 1;
  width: 100%;
  transform: rotate(-90deg);
  margin: 60px 0;
  transition: all 0.3s ease-in-out;
}
#op-hero-one .carousel-nav > div {
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
#op-hero-one .carousel-control-next:hover,
#op-hero-one .carousel-control-prev:hover {
  color: #3d3f42;
}
#op-hero-one .carousel-inner h2 {
  font-family: 'Playfair Display', serif;
  font-size: 520px;
  color: #fff;
  position: absolute;
  left: 0;
  bottom: -238px;
}
/*-------------------- About Us -------------------------*/
.op-about-us-one {
  padding-top: 250px;
  position: relative;
}
.op-about-us-one .upper-wrapper {
  overflow: visible;
}
.op-about-us-one .top-text {
  position: relative;
  padding: 0 0 75px 60px;
}
.op-about-us-one .top-text:before {
  content: '';
  width: 10000px;
  height: 1000px;
  background: linear-gradient(
    -132.59deg,
    rgba(255, 255, 255, 0.13) 0%,
    rgba(216, 216, 216, 0.13) 100%
  );
  position: absolute;
  z-index: -1;
  left: -60px;
  bottom: 0;
}
.op-about-us-one .top-text p {
  font-size: 26px;
  line-height: 42px;
  color: #303030;
}
.op-about-us-one .top-text p:first-letter {
  -webkit-initial-letter: 4 1;
  initial-letter: 4 1;
  font-size: 136px;
  vertical-align: middle;
  margin-right: 10px;
  margin-bottom: 5px;
  float: left;
}
.op-about-us-one .img-box {
  display: inline-block;
  position: relative;
}
.op-about-us-one .img-box .text {
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  font-size: 24px;
  z-index: 9;
  background: #fff;
  padding: 50px 10px;
  text-align: center;
  color: #242424;
  box-shadow: 0px 30px 50px 0px rgba(138, 138, 138, 0.1);
}

.op-about-us-one .bottom-text {
  padding: 50px 0 0 60px;
}
.op-about-us-one .bottom-text h4 {
  font-size: 32px;
  margin-bottom: 25px;
}
.op-about-us-one .bottom-text p {
  padding-bottom: 30px;
}
.op-about-us-one .bottom-text .name {
  font-size: 22px;
  margin: 30px 0 60px;
  padding-left: 40px;
  position: relative;
  color: #242424;
}
.op-about-us-one .bottom-text .name:before {
  content: '';
  position: absolute;
  width: 20px;
  height: 2px;
  background: #242424;
  top: 50%;
  left: 0;
}
.op-about-us-one .bottom-text .more-button {
  width: 280px;
  line-height: 65px;
  background: #fdf3cb;
  font-size: 18px;
  color: #857a47;
  padding: 0 20px 0 30px;
  margin-top: 60px;
  transition: all 0.3s ease-in-out;
}
.op-about-us-one .bottom-text .more-button i {
  float: right;
  font-size: 30px;
}
.op-about-us-one .bottom-text .more-button:hover {
  background: #242424;
  color: #fff;
}
/*------------------------ Project Gallery -----------------*/
.op-project-one {
  position: relative;
  z-index: 1;
  background: #fdfbf6;
  padding: 350px 0 0;
}
.op-project-one .upper-heading p {
  font-size: 24px;
  color: #202020;
  line-height: 36px;
  padding: 12px 85px 0 50px;
}
.op-project-one .inner-wrapper {
  position: relative;
  margin-top: 120px;
  overflow: hidden;
}
.op-project-one .inner-wrapper .work-text {
  position: absolute;
  top: -215px;
  right: 0;
  z-index: 1;
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  font-size: 380px;
  color: #161616;
}
.op-project-one .inner-wrapper .project-text {
  position: absolute;
  bottom: -190px;
  right: 0;
  width: 100%;
  text-align: center;
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  font-size: 390px;
  color: #fdfbf6;
}
.op-project-one .inner-wrapper .bg-wrapper {
  background: #fff;
  border-radius: 100px 0 0 0;
  padding: 280px 0 240px;
  z-index: 1;
  max-width: 90vw;
  margin: 0 auto;
}
.op-project-one #isotop-gallery-wrapper {
  margin: 0 -35px;
}
.op-project-one #isotop-gallery-wrapper .grid-sizer,
.op-project-one #isotop-gallery-wrapper .isotop-item {
  width: 50%;
  padding: 0 35px;
  margin-bottom: 70px;
}
.op-project-one #isotop-gallery-wrapper .item-content {
  position: relative;
  padding-bottom: 110px;
}
.op-project-one #isotop-gallery-wrapper .item-content .img-box {
  border: 1px solid #ededed;
  overflow: hidden;
}
.op-project-one #isotop-gallery-wrapper .item-content .img-box img {
  width: 100%;
  transition: all 0.5s ease-in-out;
}
.op-project-one #isotop-gallery-wrapper .item-content:hover .img-box img {
  transform: scale3d(1.1, 1.1, 1);
}
.op-project-one #isotop-gallery-wrapper .item-content .text {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 100px;
  padding: 50px 35px 42px 60px;
  background: #fff;
  box-shadow: -15.045px 19.966px 40px 0px rgba(240, 240, 240, 0.5);
  transition: all 0.5s ease-in-out;
}
.op-project-one #isotop-gallery-wrapper .item-content:hover .text {
  left: 15px;
}
.op-project-one #isotop-gallery-wrapper .item-content .text h3 {
  font-size: 42px;
  color: #303030;
  margin-bottom: 15px;
}
.op-project-one #isotop-gallery-wrapper .item-content .text .icon-box {
  width: 70px;
  height: 70px;
  text-align: center;
  color: #fff;
  font-size: 35px;
  line-height: 70px;
  border-radius: 50%;
  position: absolute;
  top: -35px;
  left: 60px;
  z-index: 1;
  box-shadow: 0px 15px 40px 0px rgba(189, 189, 189, 0.5);
}
.op-project-one #isotop-gallery-wrapper .item-content .text .icon-box.cl-one {
  background: #2b78ff;
}
.op-project-one #isotop-gallery-wrapper .item-content .text .icon-box.cl-two {
  background: #ff4649;
}
.op-project-one #isotop-gallery-wrapper .item-content .text .icon-box.cl-three {
  background: #ff427a;
}
.op-project-one #isotop-gallery-wrapper .item-content .text .icon-box.cl-four {
  background: #10c7ff;
}
.op-project-one #isotop-gallery-wrapper .item-content .text .read-more {
  font-family: 'Lato', sans-serif;
  display: block;
  font-weight: 300;
  font-size: 55px;
  color: #202020;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.op-project-one .more-text {
  max-width: 430px;
  margin-top: -140px;
  position: relative;
  z-index: 1;
}
.op-project-one .more-text span {
  text-transform: uppercase;
  font-size: 15px;
  color: #b5b5b5;
}
.op-project-one .more-text p {
  font-size: 20px;
  color: #000;
  padding: 15px 0 80px;
}
.op-project-one .more-text .more-button {
  display: block;
  background: #fff1b5;
  font-size: 24px;
  color: #292929;
  padding: 40px 20px 40px 30px;
  transition: all 0.3s ease-in-out;
}
.op-project-one .more-text .more-button i {
  float: right;
  font-size: 30px;
}
.op-project-one .more-text .more-button:hover {
  background: #242424;
  color: #fff;
}

/*--------------------------- Service -------------------------*/
.op-services-one {
  padding: 200px 0 180px;
}
.op-services-one .title-text {
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  font-size: 290px;
  color: #2c2c2c;
  transform: rotate(90deg);
}
.op-services-one p {
  font-size: 24px;
  line-height: 35px;
  color: #2c2c2c;
  padding: 60px 0 125px;
}
.op-services-one .list-item {
  padding-bottom: 80px;
}
.op-services-one .list-item h6 {
  font-size: 24px;
  font-weight: 700;
  color: #202020;
  padding-bottom: 25px;
}
.op-services-one .list-item ul li {
  font-size: 18px;
  line-height: 35px;
  color: #5e5e5e;
  position: relative;
  padding-left: 20px;
}
.op-services-one .list-item ul li:before {
  content: '+';
  position: absolute;
  left: 0;
  top: 0;
  line-height: 35px;
}
/*-------------------------- Testimonial ----------------------*/
.op-testimonial-one {
  position: relative;
  z-index: 1;
  background: #fdfbf6;
  padding: 200px 0 100px;
}
.op-testimonial-one:before {
  content: url(../images/shape/shape-69.svg);
  position: absolute;
  right: 7%;
  top: -100px;
  z-index: 5;
}
.op-testimonial-one .row {
  margin: 0 -25px;
}
.op-testimonial-one .row [class*='col-'] {
  padding: 0 25px;
}
.op-testimonial-one .theme-title-four {
  margin-bottom: 100px;
}
.op-testimonial-one .client-text-box {
  background: #fff;
  padding: 50px 30px 60px 60px;
  box-shadow: 0px 20px 40px 0px rgba(162, 162, 162, 0.05);
  margin-bottom: 65px;
}
.op-testimonial-one .client-text-box img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.op-testimonial-one .client-text-box blockquote {
  font-size: 24px;
  line-height: 38px;
  color: #2c2c2c;
  padding: 30px 0 45px;
  margin: 0;
}
.op-testimonial-one .client-text-box h6 {
  font-size: 24px;
  color: #202020;
  font-weight: 600;
  padding-bottom: 4px;
}
.op-testimonial-one .client-text-box .desg {
  font-size: 20px;
  color: #b7b7b7;
}
/*----------------- Partner Section -------------------*/
.op-partner-section-one {
  padding: 130px 0 150px;
}
.op-partner-section-one .img-box a {
  display: block;
  height: 100%;
}
.op-partner-section-one .img-box img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
}
.op-partner-section-one .img-box {
  background: #fff;
  border-radius: 50%;
  box-shadow: 15.436px 30.294px 50px 0px rgba(233, 236, 241, 0.5);
  width: 175px;
  height: 175px;
  margin: 0 auto 25px;
  transition: all 0.4s ease-in-out;
}
.op-partner-section-one .img-box:hover {
  transform: scale(1.1);
}
.op-partner-section-one .img-box.bx-b {
  width: 121px;
  height: 121px;
  margin-top: 45px;
}
.op-partner-section-one .img-box.bx-d {
  width: 151px;
  height: 151px;
  margin-top: 25px;
}
.op-partner-section-one .img-box.bx-f {
  width: 135px;
  height: 135px;
  margin-top: 20px;
}
.op-partner-section-one .img-box.bx-g {
  width: 197px;
  height: 197px;
}
.op-partner-section-one .img-box.bx-h {
  width: 138px;
  height: 138px;
  margin-top: 20px;
}
/*-------------------- Footer --------------------*/
.op-footer-one h2 {
  font-size: 72px;
  font-weight: normal;
  line-height: 85px;
  color: #202020;
}
.op-footer-one h2 a {
  color: #202020;
}
.op-footer-one .bottom-text {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  margin-top: 120px;
  padding: 70px 0;
}
.op-footer-one .bottom-text ul {
  margin: 0 -40px;
}
.op-footer-one .bottom-text ul li {
  float: left;
  padding: 0 40px;
}
.op-footer-one .bottom-text ul li a {
  font-size: 20px;
  color: #202020;
  transition: all 0.3s ease-in-out;
}
.op-footer-one .bottom-text ul li a:hover {
  color: #ff4649;
}
.op-footer-one .bottom-text p {
  font-size: 18px;
  color: #c9c9c9;
}
/*-----------------------------------
            STUDIO PORTFOLIO TWO                 
--------------------------------------*/
.studio-box-container {
  padding: 50px;
  background: #efefef;
  z-index: 5;
}
.studio-box-container .main-page-wrapper {
  background: #fff;
  position: relative;
}
.studio-menu-two {
  padding: 30px 100px 0;
}
.studio-menu-two.fixed {
  padding: 0 100px;
  left: 50px;
  right: 50px;
  width: auto;
}
/*-------------------- Theme Studio Banner two -------------------*/
#studio-banner-two {
  position: relative;
  padding: 200px 100px 200px;
  max-width: 1920px;
  margin: 0 auto;
}
#studio-banner-two .main-text-wrapper {
  position: relative;
  max-width: 600px;
  z-index: 2;
}
#studio-banner-two .main-text-wrapper h1 {
  font-weight: 500;
  font-size: 100px;
  line-height: 100px;
  color: #161616;
}
#studio-banner-two .main-text-wrapper .button-link {
  font-size: 18px;
  margin-top: 30px;
}
#studio-banner-two .main-text-wrapper .button-link i {
  vertical-align: -1px;
  margin-left: 3px;
}
#studio-banner-two .illustration {
  position: absolute;
  right: 100px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  width: 50%;
}
/*---------------- Action Banner Three ---------------*/
.action-banner-three {
  padding: 70px 0 35px;
  margin-top: 50px;
  position: relative;
  z-index: 5;
}
.action-banner-three .main-wrapper {
  max-width: 960px;
  margin: 0 auto;
  text-align: center;
}
.action-banner-three .main-wrapper h3 {
  font-weight: 500;
  color: #fff;
  font-size: 20px;
  padding-bottom: 65px;
}
.action-banner-three .main-wrapper p {
  color: #fff;
  font-size: 25px;
  line-height: 35px;
  padding: 55px 0 20px;
}
.action-banner-three .main-wrapper img {
  margin: 0 auto;
}
/*-------------------- Our Feature -----------------*/
.studio-feature-area {
  background: url(../images/home/4.png) no-repeat top center;
  background-size: cover;
  padding: 140px 0 180px;
}
.studio-feature-area .theme-title-one {
  margin-bottom: 40px;
}
.studio-feature-area .feature-block {
  background: #fff;
  border: 1px solid #f6f6f6;
  overflow: hidden;
  position: relative;
  z-index: 5;
  padding: 180px 30px 25px 40px;
  margin-top: 45px;
}

.studio-feature-area .feature-block .icon-s {
  position: absolute;
  font-size: 65px;
  left: 40px;
  top: 52px;
}
.studio-feature-area .feature-block .title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 30px;
}
.studio-feature-area .feature-block .detail-button {
  font-size: 30px;
  margin-top: 20px;
}
/*---------------- Our Project ---------------*/
.studio-project-area {
  padding: 90px 0 150px;
  position: relative;
}
.studio-project-area:before {
  content: '';
  background-image: -moz-linear-gradient(
    -90deg,
    rgba(249, 248, 246, 0.18039) 0%,
    rgb(255, 255, 255) 100%
  );
  background-image: -webkit-linear-gradient(
    -90deg,
    rgba(249, 248, 246, 0.18039) 0%,
    rgb(255, 255, 255) 100%
  );
  background-image: -ms-linear-gradient(
    -90deg,
    rgba(249, 248, 246, 0.18039) 0%,
    rgb(255, 255, 255) 100%
  );
  position: absolute;
  top: -200px;
  left: 0;
  right: 0;
  height: 285px;
  z-index: 49;
}
.studio-project-area .studio-title {
  margin-bottom: 90px;
}
.studio-project-holder {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 60px;
}
.studio-project-holder a {
  display: block;
}
.studio-project-holder img {
  width: 100%;
  transition: all 0.5s ease-in-out;
}
.studio-project-holder:hover {
  box-shadow: 0px 13px 35px -12px rgba(35, 35, 35, 0.15);
}
.studio-project-holder:hover img {
  transform: scale3d(1.1, 1.1, 1);
}
.studio-project-holder .text {
  position: absolute;
  top: 50px;
  left: 50px;
}
.studio-project-holder .text p {
  font-size: 24px;
  text-transform: uppercase;
  color: #fff;
  padding-bottom: 10px;
}
.studio-project-holder .text h2 {
  font-size: 42px;
  color: #fff;
  font-weight: 500;
}
.studio-project-area .text-block h3 {
  font-size: 32px;
  line-height: 39px;
  font-weight: normal;
  color: #000000;
}
.studio-project-area .text-block p {
  padding: 45px 0 50px;
  color: rgba(0, 0, 0, 0.45);
}
.studio-project-area .text-block.scree-lg {
  padding-top: 60px;
}
.studio-project-area .text-block.scree-mobile {
  display: none;
}
/*------------------ Testimonial ---------------*/
.testimonial-studio-vr {
  margin: 100px auto 50px;
  padding: 135px 0 0;
  position: relative;
  z-index: 5;
}
.testimonial-studio-vr:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 590px;
  top: 0;
  left: 0;
  background: #f7f9ff;
  z-index: -1;
}
.testimonial-studio-vr:before {
  content: url(../images/icon/icon69.svg);
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.studio-client-slider {
  max-width: 1920px;
  margin: 0 auto;
}
.testimonial-studio-vr .studio-title {
  padding-bottom: 100px;
}
.studio-client-slider .customer-block {
  background: #fff;
  position: relative;
  padding: 50px 50px 60px 50px;
  margin-bottom: 80px;
  box-shadow: 0px 10px 25px 0px rgba(123, 147, 171, 0.15);
}
.studio-client-slider .customer-block:before {
  content: '\f111';
  font-family: 'Flaticon';
  position: absolute;
  right: 50px;
  bottom: 60px;
  font-size: 28px;
  transform: rotate(180deg);
}
.studio-client-slider .customer-block .customer-img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.studio-client-slider .customer-block p {
  font-size: 18px;
  line-height: 34px;
  color: #2c2c2c;
  padding: 30px 0 35px;
}
.studio-client-slider .customer-block .name {
  font-size: 24px;
  color: #212121;
}
.studio-client-slider .customer-block .customer-info span {
  color: #b7b7b7;
}
.studio-client-slider .center .customer-block p,
.studio-client-slider .center .customer-block .name,
.studio-client-slider .center .customer-block .customer-info span,
.studio-client-slider .center .customer-block:before {
  color: #fff;
}
.owl-theme.studio-client-slider .owl-nav [class*='owl-'] {
  background: transparent;
  font-size: 25px;
  color: #d2d2d2;
  padding: 0;
  margin: 0 10px;
}
/*------------------------ Contact Us -------------------*/
.studio-contact-area {
  position: relative;
  padding: 100px 0 200px;
  z-index: 5;
}
.studio-contact-area:before {
  content: url(../images/shape/5.png);
  position: absolute;
  top: -100px;
  right: 0;
  z-index: -1;
}
.studio-contact-area .contact-text p {
  font-size: 20px;
  color: #888888;
  position: relative;
  padding: 65px 0 5px;
}
.studio-contact-area .contact-text .call-to-dial {
  font-size: 26px;
}
.studio-contact-area .studio-contact-style {
  margin-left: 60px;
}
.studio-contact-style {
  background: #fff;
  padding: 65px 60px;
  box-shadow: 19.799px 19.799px 100px 0px rgba(96, 96, 96, 0.08);
}
.studio-contact-style input,
.studio-contact-style textarea {
  width: 100%;
  border: none;
  border-bottom: 2px solid #eaeaea;
}
.studio-contact-style input {
  height: 50px;
  margin-bottom: 55px;
}
.studio-contact-style textarea {
  height: 120px;
  resize: none;
  margin-bottom: 50px;
}
.studio-contact-style input:focus,
.studio-contact-style textarea:focus {
  border-bottom-color: #6a6a6a;
}
/*-------------------- Footer -----------------*/
.studio-footer-one {
  background: #151515;
  position: relative;
  z-index: 5;
  padding: 140px 0 50px;
  overflow: hidden;
}
.studio-footer-one:before {
  content: url(../images/home/5.png);
  position: absolute;
  width: 100%;
  bottom: -20px;
  left: 0;
  z-index: -1;
  opacity: 0.7;
}
.studio-footer-one .top-footer .about-widget {
  margin-top: 15px;
}
.studio-footer-one .top-footer h2 {
  font-size: 48px;
  color: #fff;
  font-weight: normal;
  padding-bottom: 70px;
}
.studio-footer-one .top-footer .title {
  font-size: 24px;
  color: #fff;
  padding-bottom: 25px;
  font-weight: normal;
}
.studio-footer-one .top-footer .footer-list ul li a {
  line-height: 36px;
  display: block;
  color: #6d7175;
  font-size: 16px;
}
.studio-footer-one .top-footer .footer-list ul.soical-icon li {
  display: inline-block;
  margin: 10px 15px 0 0;
}
.studio-footer-one .top-footer .footer-list ul.soical-icon li a {
  font-size: 18px;
}
.studio-footer-one .bottom-footer {
  padding-top: 100px;
}
.studio-footer-one .bottom-footer p {
  color: rgba(255, 255, 255, 0.5);
}
.studio-footer-one .bottom-footer ul li {
  display: inline-block;
}
.studio-footer-one .bottom-footer ul li a {
  color: rgba(255, 255, 255, 0.5);
  margin: 0 5px;
}
.studio-footer-one .bottom-footer ul li a:hover {
  color: #fff;
}

/*--------------------- Theme Solid Inner Banner Breadcrumbs -----------------*/
.solid-inner-banner {
  background: #fafcff;
  text-align: center;
  padding: 265px 0 0;
}
.solid-inner-banner.white-bg {
  background: #fff;
}
.solid-inner-banner .page-title {
  font-family: 'CircularStdmed';
  font-size: 60px;
  color: #313131;
  padding-bottom: 150px;
}
.solid-inner-banner .page-breadcrumbs {
  background: #fff;
  border-bottom: 1px solid #eeeeee;
  padding: 25px 0;
}
.solid-inner-banner .page-breadcrumbs li {
  display: inline-block;
  font-size: 22px;
  color: #244574;
  margin: 0 3px;
}
.solid-inner-banner .page-breadcrumbs li a {
  color: #244574;
}

/*------------------------ Our Service Modren -------------------*/
.service-modren .service-block .img-box img {
  border-radius: 5px;
}
.service-modren .service-block .service-info .tag {
  display: inline-block;
  line-height: 40px;
  font-size: 15px;
  padding: 0 35px;
  border-radius: 3px;
}
.service-modren .service-block .service-info .tag.color-one {
  background: #ffeaee;
  color: #ff8aa0;
}
.service-modren .service-block .service-info .tag.color-two {
  background: #fef9da;
  color: #ead24c;
}
.service-modren .service-block .service-info .tag.color-three {
  background: #e7fff5;
  color: #70e9ae;
}
.service-modren .service-block .service-info .tag.color-four {
  background: #edf9ff;
  color: #7fd8ff;
}

.service-modren .service-block .service-info .read-more {
  color: #cfd5df;
}
.service-modren .service-block .service-info .service-title {
  margin: 40px 0 45px;
}
.service-modren .service-block {
  padding-bottom: 150px;
}
.service-modren .service-block:last-child {
  padding-bottom: 0;
}
.service-modren .service-block:nth-child(odd) .service-info {
  padding-right: 30px;
}
.service-modren .service-block:nth-child(even) .service-info {
  padding-left: 30px;
}

/*--------------------- Contact Banner/Agency ---------------------*/
.agn-contact-banner {
  text-align: center;
  background-color: #f9fbfe;
  background-image: url(../images/shape/map2.png);
  background-position: center center;
  background-repeat: no-repeat;
}
.agn-contact-banner .title {
  font-size: 48px;
  line-height: 64px;
  color: #233d63;
  padding-bottom: 35px;
}
.agn-contact-banner .contact-button {
  font-size: 20px;
  line-height: 56px;
  width: 220px;
}

/*--------------------- Blog Details --------------------*/
.blog-details .post-data {
  padding: 40px 0 60px;
}
.blog-details .post-data .title {
  padding: 15px 0 20px;
}
.blog-details .post-data p {
  padding-bottom: 30px;
}
.blog-details .post-data .quote-one {
  font-size: 32px;
  line-height: 45px;
  margin: 42px 0 60px;
  color: #3e3e3e;
  position: relative;
  padding-left: 90px;
}
.blog-details .post-data .quote-one:before {
  content: '';
  width: 60px;
  height: 3px;
  background: #3e3e3e;
  position: absolute;
  top: 17px;
  left: 0;
}
.blog-details .post-data .bold-text {
  font-family: 'CircularStdmed';
  font-size: 20px;
  line-height: 32px;
  color: #3e3e3e;
  padding-bottom: 30px;
}
.blog-details .post-tag-area .tags li {
  display: inline-block;
  font-size: 20px;
  color: #3e3e3e;
}
.blog-details .post-tag-area .tags li:first-child {
  font-family: 'CircularStdmed';
}
.blog-details .post-tag-area .tags li a {
  font-size: 18px;
  color: #989ca2;
}
.blog-details .post-tag-area .tags li a:hover {
  color: #3e3e3e;
}
.blog-details .post-tag-area .share-icon li {
  font-family: 'CircularStdmed';
  display: inline-block;
  font-size: 20px;
  color: #3e3e3e;
}
.blog-details .post-tag-area .share-icon li a {
  font-size: 20px;
  color: #d3d3d3;
  margin-left: 13px;
}
.blog-details .post-tag-area ul {
  padding-bottom: 20px;
}
.blog-details .post-tag-area {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px;
}
.blog-details .inner-block-title {
  font-family: 'CircularStdmed';
  font-size: 36px;
  color: #3e3e3e;
  padding-bottom: 28px;
}
.blog-details .user-comment-area .single-comment .user-comment-data .date {
  font-style: italic;
  color: #989ca2;
}
.blog-details .user-comment-area .single-comment {
  padding: 50px 0 40px;
  border-bottom: 1px solid #e5e5e5;
}
.blog-details .user-comment-area .single-comment.comment-reply {
  margin-left: 100px;
}
.blog-details .user-comment-area .single-comment .reply {
  font-family: 'CircularStdmed';
  width: 70px;
  height: 30px;
  border: 2px solid #e7e7e7;
  border-radius: 3px;
  font-size: 14px;
  text-transform: uppercase;
  color: #3e3e3e;
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
}
.blog-details .user-comment-area .single-comment .reply:hover {
  color: #fff;
}
.blog-details .comment-form-area form {
  padding-top: 55px;
}
.blog-details .comment-form-area form input,
.blog-details .comment-form-area form textarea {
  border: 1px solid #dcdcdc;
  width: 100%;
  max-width: 100%;
  margin-bottom: 35px;
  color: #989ca2;
}
.blog-details .comment-form-area form input:focus,
.blog-details .comment-form-area form textarea:focus {
  border-color: #383838;
}
.blog-details .comment-form-area form input {
  height: 60px;
  padding: 0 25px;
}
.blog-details .comment-form-area form textarea {
  height: 260px;
  max-height: 240px;
  padding: 25px;
}
.blog-details .comment-form-area form ::placeholder {
  color: #989ca2;
  opacity: 1;
}
.blog-details .comment-form-area form :-ms-input-placeholder {
  color: #989ca2;
}
.blog-details .comment-form-area form ::-ms-input-placeholder {
  color: #989ca2;
}
.blog-details .blog-hero-banner {
  padding: 480px 0 220px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  animation: imageBgAnim 50s infinite linear alternate;
}
.blog-details .blog-custom-container {
  max-width: 980px;
  padding: 0 15px;
  margin: 0 auto;
}
.blog-details .blog-hero-banner .date {
  font-size: 20px;
  color: #fff;
  margin-bottom: 30px;
}
.blog-details .blog-hero-banner .blog-title {
  font-family: 'CircularStdmed';
  font-size: 48px;
  line-height: 55px;
  color: #fff;
  width: 70%;
}
.blog-details-fg .blog-fg-data .post-data {
  padding: 0;
}
.blog-details-fg .blog-fg-data {
  position: relative;
  z-index: 1;
  margin-top: -150px;
}
.blog-details-fg .blog-fg-data .custom-container-bg {
  padding: 0 75px 0;
  background: #fff;
}
.blog-details-fg .blog-fg-data .post-data .sub-heading {
  font-family: 'CircularStdmed';
  font-size: 28px;
  color: #3d3d3d;
  padding: 30px 0 25px;
}
.blog-details-fg .blog-fg-data .blog-img-gallery {
  padding: 50px 0 80px;
}
.blog-details-fg .blog-fg-data .blog-img-gallery img {
  width: 100%;
}
.blog-details-fg .blog-fg-data .video-banner-blog {
  background: url(../images/blog/34.jpg) no-repeat center;
  background-size: cover;
  background-attachment: fixed;
  height: 615px;
  position: relative;
  margin: 100px 0 105px;
}
.blog-details-fg .blog-fg-data .video-banner-blog .video-button {
  display: block;
  width: 100px;
  height: 100px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 100px;
  color: #000000;
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  padding-left: 10px;
  transform: translate(-50%, -50%);
}
.blog-details-fg .blog-fg-data .video-banner-blog .video-button:hover {
  color: #fff;
}
.blog-details .post-data .quote-two {
  display: inline-block;
  font-size: 23px;
  line-height: 32px;
  font-style: italic;
  margin: 0 0 45px;
  color: #3e3e3e;
  position: relative;
  padding-right: 90px;
}
.blog-details .post-data .quote-two span {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  width: 100px;
  height: 2px;
  background: #535353;
}
.blog-details.blog-details-fg .user-comment-area .single-comment {
  border-bottom: none;
  padding-bottom: 30px;
}
.blog-details-fg .comment-form-area {
  background: #f8fcff;
  padding: 130px 0 140px;
}
.blog-details-fg .comment-form-area .custom-container-bg {
  background: transparent;
}
.blog-details.blog-details-fg .comment-form-area form input,
.blog-details.blog-details-fg .comment-form-area form textarea {
  border: none;
  border-bottom: 2px solid #383838;
  background: transparent;
}
.blog-details.blog-details-fg .comment-form-area form input {
  padding: 0;
}
.blog-details.blog-details-fg .comment-form-area form textarea {
  padding: 25px 0;
}
.blog-details.blog-details-fg .comment-form-area form ::placeholder {
  color: #b8b8b8;
  opacity: 1;
}
.blog-details.blog-details-fg .comment-form-area form :-ms-input-placeholder {
  color: #b8b8b8;
}
.blog-details.blog-details-fg .comment-form-area form ::-ms-input-placeholder {
  color: #b8b8b8;
}

.shop-details .user-comment-area {
  padding-right: 200px;
}
.user-comment-area .single-comment {
  padding-bottom: 50px;
}
.user-comment-area .single-comment:last-child {
  padding-bottom: 0;
}
.user-comment-area .single-comment .user-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.user-comment-area .single-comment .user-comment-data {
  padding-left: 30px;
  position: relative;
}
.user-comment-area .single-comment .user-comment-data .name {
  font-family: 'CircularStdmed';
  font-size: 20px;
  color: #3e3e3e;
  padding-bottom: 5px;
}
.user-comment-area .single-comment .user-comment-data .rating li {
  display: inline-block;
}
.user-comment-area .single-comment .user-comment-data .rating li a {
  font-size: 15px;
  color: #fbc134;
  margin-right: 1px;
}
.user-comment-area .single-comment .user-comment-data p {
  padding-top: 15px;
  color: #989ca2;
}
.realated-product .title {
  font-size: 36px;
  padding-bottom: 55px;
}
.realated-product.product-showcase .single-product-case {
  margin: 0;
}
.realated-product .owl-theme .owl-nav [class*='owl-'] {
  background: transparent;
  padding: 0;
  font-size: 32px;
  margin: 0 0 0 15px;
  color: #cacaca;
}
/*--------------------------- Our Blog ------------------------------*/
.masnory-blog-wrapper {
  margin: 0 -25px;
}
.masnory-blog-wrapper .grid-sizer,
.masnory-blog-wrapper .isotop-item {
  width: 33.333333%;
  padding: 0 25px;
}
.our-blog .img-holder {
  background: #292928;
  overflow: hidden;
  position: relative;
}
.our-blog .img-holder img {
  width: 100%;
  transition: all 0.6s ease-in;
}
.our-blog .single-blog-post:hover .img-holder img {
  opacity: 0.6;
  transform: scale3d(1.1, 1.1, 1);
}
.our-blog .img-holder .video-button {
  display: block;
  width: 100px;
  height: 100px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 100px;
  color: #000000;
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  padding-left: 10px;
  transform: translate(-50%, -50%);
}
.our-blog .single-blog-post:hover .img-holder .video-button {
  color: #fff;
}
.our-blog .post-data .date {
  font-size: 20px;
  color: #b5b5b5;
  text-transform: capitalize;
}
.our-blog .post-data .date:hover {
  color: #363636;
  text-decoration: underline;
}
.our-blog .post-data .blog-title-one {
  font-family: 'CircularStdmed';
  font-size: 24px;
  line-height: 35px;
  color: #3e3e3e;
}
.our-blog .post-data .blog-title-two {
  font-family: 'CircularStdmed';
  font-size: 28px;
  line-height: 40px;
}
.our-blog .post-data .title a {
  color: #3e3e3e;
}
.our-blog .post-data p {
  color: #76797e;
  line-height: 30px;
}
.our-blog .post-data .read-more {
  font-size: 35px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 20px;
}
.our-blog .post-data .read-more:hover {
  color: #5e5e5e;
}
.our-blog .bg-solid-post.single-blog-post .post-data {
  padding: 100px 65px 70px;
  text-align: center;
  position: relative;
  background: #ff4c57;
  z-index: 1;
}
.our-blog .bg-solid-post.single-blog-post .post-data:before {
  content: url(../images/icon/icon58.svg);
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: -1;
  opacity: 0;
  transition: all 1s ease-in-out;
}
.our-blog .bg-solid-post.single-blog-post.show-pr .post-data:before {
  top: 45px;
  opacity: 1;
}
.our-blog .bg-solid-post.single-blog-post .post-data .title {
  font-family: 'CircularStdmed';
  font-size: 36px;
  line-height: 50px;
  margin: 0 0 38px;
}
.our-blog .bg-solid-post.single-blog-post .post-data .title a {
  color: #fff;
}
.our-blog .bg-solid-post.single-blog-post .post-data .title a:hover {
  text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.3);
}
.our-blog .bg-solid-post.single-blog-post .post-data .author {
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
}
.blog-masonry .single-blog-post .post-data {
  padding: 28px 20px 18px 30px;
  border: 1px solid #ededed;
  border-top: none;
}
.blog-masonry .single-blog-post {
  margin-bottom: 35px;
}
.blog-masonry .single-blog-post .title {
  margin: 18px 0 15px;
}
.blog-default .single-blog-post .post-data {
  padding-top: 45px;
}
.blog-default .single-blog-post .post-data .title {
  margin: 15px 0 20px;
}
.blog-default .single-blog-post .post-data .read-more {
  margin-top: 26px;
}
.blog-default .single-blog-post {
  margin-bottom: 90px;
}
.blog-default .single-blog-post.blog-text-style .post-data {
  border: 1px solid #ededed;
  padding: 45px 55px 35px;
}
.blog-filter-title {
  text-align: center;
  padding: 270px 0 100px;
}
.blog-filter-title .upper-title {
  font-family: 'CircularStdmed';
  font-size: 16px;
  text-transform: uppercase;
  color: rgba(62, 62, 62, 0.3);
  padding-bottom: 45px;
}
.blog-filter-title .main-title {
  font-family: 'CircularStdmed';
  font-size: 60px;
  line-height: 60px;
  color: #313131;
}
.blog-filer {
  padding-left: 50px;
  padding-right: 50px;
}
.blog-filer .blog-filter-nav {
  border-bottom: 1px solid #5d5d5d;
  margin-bottom: 80px;
}
.blog-filer .blog-filter-nav li {
  font-family: 'CircularStdmed';
  float: left;
  width: 16.66666666%;
  text-align: center;
  margin: 15px 0 0;
  font-size: 18px;
  color: rgba(62, 62, 62, 0.4);
  position: relative;
  padding-bottom: 20px;
}
.blog-filer .blog-filter-nav li span {
  cursor: pointer;
}
.blog-filer .blog-filter-nav li.is-checked {
  color: #3e3e3e;
}
.blog-filer .blog-filter-nav li:before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background: #fff;
  border-top: 1px solid #5d5d5d;
  border-right: 1px solid #5d5d5d;
  transform: rotate(135deg);
  bottom: -8px;
  left: 50%;
  margin-left: -10px;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.blog-filer .blog-filter-nav li.is-checked:before {
  opacity: 1;
  bottom: -11px;
}
.blog-filer .masnory-blog-wrapper .grid-sizer,
.blog-filer .masnory-blog-wrapper .isotop-item {
  width: 25%;
  padding: 0 25px;
}
.our-blog.blog-filer .post-data {
  padding-top: 28px;
}
.our-blog.blog-filer .post-data .blog-title-one {
  font-size: 26px;
  margin: 15px 0 16px;
}
.our-blog.blog-filer .post-data .read-more {
  margin-top: 15px;
}
.our-blog.blog-filer .single-blog-post {
  margin-bottom: 100px;
}
.our-blog .full-grid-container {
  max-width: 1030px;
  margin: 0 auto;
  padding: 0 15px;
}
.our-blog .full-grid-container .bg-solid-post.single-blog-post .post-data {
  padding-left: 90px;
  padding-right: 90px;
}
/*----------------------- Our Service Minimal ----------------------*/
.our-service .service-block .service-title {
  font-size: 48px;
  line-height: 54px;
}
.our-service .service-block .service-title a {
  color: #3e3e3e;
}
.our-service .service-block .read-more {
  font-size: 38px;
  line-height: 20px;
}
.service-minimal .row {
  margin: 0 -110px;
}
.service-minimal .row [class*='col-'] {
  padding: 0 110px;
}
.service-minimal .service-block .icon-box {
  height: 80px;
  margin-bottom: 50px;
}
.service-minimal .service-block p {
  font-size: 17px;
  line-height: 32px;
  color: #798598;
  padding: 35px 0;
}
.service-minimal .service-block .read-more {
  color: #cfd5df;
}
.service-minimal .service-block {
  margin-bottom: 140px;
}

/*-------------------------- Theme Footer One -------------------*/
.theme-footer-one {
  position: relative;
  padding-top: 125px;
}
.theme-footer-one .shape-two {
  position: absolute;
  right: 10%;
  top: 50%;
  animation: rotated 18s infinite linear;
}
.theme-footer-one.top-border {
  border-top: 1px solid #ededed;
  padding-top: 95px;
}
.theme-footer-one .about-widget .email {
  font-size: 18px;
  color: #76797e;
  margin: 15px 0 15px;
  display: block;
}
.theme-footer-one .about-widget .phone {
  font-size: 22px;
}
.theme-footer-one .top-footer .title {
  font-size: 22px;
  padding: 12px 0 25px;
}
.theme-footer-one .top-footer .footer-list ul li a {
  display: block;
  line-height: 38px;
  font-size: 17px;
  color: #76797e;
}
.theme-footer-one .top-footer .footer-information p {
  padding: 0 0 30px;
  color: #76797e;
}
.theme-footer-one .top-footer .footer-information ul li {
  display: inline-block;
}
.theme-footer-one .top-footer .footer-information ul li a {
  width: 40px;
  height: 40px;
  border: 1px solid #dbdbdb;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  color: #dbdbdb;
  margin-right: 10px;
}
.theme-footer-one .top-footer .footer-information ul li a:hover {
  color: #fff;
}
.theme-footer-one .bottom-footer {
  border-top: 1px solid #ededed;
  margin-top: 90px;
  padding: 35px 0;
}
.theme-footer-one .bottom-footer p {
  float: left;
  color: rgba(87, 102, 126, 0.8);
}
.theme-footer-one .bottom-footer ul {
  float: right;
}
.theme-footer-one .bottom-footer ul li {
  display: inline-block;
}
.theme-footer-one .bottom-footer ul li a {
  line-height: 28px;
  color: rgba(87, 102, 126, 0.8);
  margin-left: 15px;
}
.theme-footer-one .shape-one {
  position: absolute;
  width: 54px;
  height: 54px;
  border: 6px solid #27c3ff;
  border-radius: 50%;
  position: absolute;
  left: -22px;
  top: 48%;
}
.theme-footer-one .shape-one:before {
  content: '';
  position: absolute;
  top: -55px;
  left: -55px;
  right: -55px;
  bottom: -55px;
  border: 1px solid #ecf4ff;
  border-radius: 50%;
}

/*--------------------- ARCH Contact --------------------*/
.arch-contact {
  background-size: cover;
  height: 600px;
  position: relative;
}
.arch-contact .form-wrapper {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -160px;
  z-index: 5;
}
.arch-contact .form-wrapper .inner-wrapper {
  background: #fff;
  padding: 70px 40px 60px 75px;
  box-shadow: 0px 50px 100px 0px rgba(3, 3, 3, 0.05);
}
.arch-contact .form-wrapper form input,
.arch-contact .form-wrapper form textarea {
  width: 100%;
  border: none;
  border-bottom: 1px solid #000000;
  margin-bottom: 30px;
  color: #848484;
}
.arch-contact .form-wrapper form input {
  padding-bottom: 12px;
}
.arch-contact .form-wrapper form textarea {
  height: 105px;
  max-height: 105px;
  resize: none;
}
.arch-contact .form-wrapper form button {
  width: 190px;
  line-height: 55px;
  text-transform: uppercase;
}
.arch-contact .form-wrapper .contact-text {
  padding-left: 50px;
}
.arch-contact .form-wrapper .contact-text .main-title {
  font-family: 'CircularStdmed';
}
.arch-contact .form-wrapper .contact-text p {
  font-size: 20px;
  color: #959595;
  font-weight: 300;
  padding: 15px 0 10px;
}
.arch-contact .form-wrapper .contact-text .call-us {
  font-size: 24px;
}

/*------------------------------ Sign Up ----------------------------*/
.signUp-illustration {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 38%;
  background: #f8faff;
  z-index: -1;
}
.signUp-illustration img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.signUp-page {
  position: relative;
  min-height: 100vh;
  z-index: 5;
}
.signUp-minimal {
  padding-left: 15px;
  padding-right: 15px;
}
.signUp-minimal .sign-up-form-wrapper {
  max-width: 650px;
  margin: 0 auto;
  border: 1px solid #e0e0e0;
  padding: 70px 80px 60px;
  position: relative;
}
.signUp-page .shape-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}
.signUp-page .shape-wrapper span {
  position: absolute;
  display: block;
  width: 26px;
  height: 26px;
  border-radius: 50%;
}
.signUp-page .shape-wrapper span:nth-child(1) {
  background: #ff9898;
  top: 0;
  left: 20%;
  animation: scale-upOne 3.9s alternate infinite ease-in;
}
.signUp-page .shape-wrapper span:nth-child(2) {
  background: #abafff;
  top: 14%;
  right: 7%;
  animation: scale-upOne 6s alternate infinite ease-in;
}
.signUp-page .shape-wrapper span:nth-child(3) {
  background: #67f5e2;
  top: 48%;
  right: 62%;
  margin-right: -13px;
  animation: scale-upOne 5s alternate infinite ease-in;
}
.signUp-page.signUp-minimal .shape-wrapper span:nth-child(3) {
  right: 88%;
}
.signUp-page .shape-wrapper span:nth-child(4) {
  background: #ffb3b3;
  bottom: 5%;
  right: 25%;
  animation: scale-upOne 4s alternate infinite ease-in;
}
.signUp-page .shape-wrapper span:nth-child(5) {
  background: #ffe281;
  bottom: 4%;
  left: 5%;
  animation: scale-upOne 5s alternate infinite ease-in;
}
.signUp-page .title-area p {
  font-size: 20px;
  padding: 5px 0 35px;
}
.signUp-page .social-icon-wrapper a {
  display: block;
  text-align: center;
  line-height: 55px;
  font-size: 20px;
  color: #fff;
  margin-top: 10px;
}
.signUp-page .social-icon-wrapper .gmail {
  background: #d93025;
}
.signUp-page .social-icon-wrapper .facebook {
  background: #4267b2;
}
.signUp-page .social-icon-wrapper a .fa {
  font-size: 18px;
  margin-right: 5px;
  vertical-align: 1px;
}
.signUp-page .or-text {
  position: relative;
  text-align: center;
  margin: 50px 0 45px;
}
.signUp-page .or-text:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background: #e2e2e2;
  left: 0;
  top: 14px;
  z-index: -1;
}
.signUp-page .or-text span {
  display: inline-block;
  font-size: 13px;
  text-transform: uppercase;
  color: #454545;
  background: #fff;
  width: 50px;
}
.signUp-page form ::placeholder {
  color: #bcbcbc;
  opacity: 1; /* Firefox */
}
.signUp-page form :-ms-input-placeholder {
  color: #bcbcbc;
}
.signUp-page form ::-ms-input-placeholder {
  color: #bcbcbc;
}
.signUp-page form .input-group {
  position: relative;
  z-index: 1;
  margin-bottom: 35px;
}
.signUp-page form .input-group input {
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background: transparent;
  height: 60px;
  font-size: 18px;
  color: #454545;
  position: relative;
  padding: 0 20px;
}
.signUp-page form .input-group label {
  font-weight: normal;
  font-size: 15px;
  color: #bcbcbc;
  line-height: 60px;
  position: absolute;
  left: 20px;
  top: 0;
  z-index: -1;
  margin: 0;
  transition: all 0.3s ease-in-out;
}
.signUp-page form .input-group input:focus ~ label,
.signUp-page form .input-group input:valid ~ label {
  top: -12px;
}
.signUp-page form .input-group input:focus {
  border-color: #393939;
}
.signUp-page form .input-group input:focus,
.signUp-page form .input-group input:valid {
  padding-top: 18px;
}
.signUp-page .selectize-control {
  width: 100%;
}
.signUp-page .input-group .selectize-input {
  width: 100%;
  line-height: 60px;
  height: 60px;
  border-radius: 5px;
  border: none;
  border: 1px solid #e0e0e0;
  box-shadow: none;
  outline: none;
  padding: 0 20px;
  background: transparent;
}
.signUp-page .input-group .selectize-input input {
  font-size: 15px;
}
.signUp-page .selectize-input .item {
  font-size: 18px;
  color: #454545;
}
.signUp-page .selectize-dropdown {
  background: #fff;
  border: 1px solid #e9e9e9;
  border-top: none;
  box-shadow: 0px 50px 100px 0px rgba(229, 232, 235, 0.2);
  cursor: pointer;
}
.signUp-page .selectize-dropdown .option {
  font-size: 14px;
  color: #1a1a1a;
  line-height: 22px;
}
.signUp-page .selectize-dropdown .option:hover,
.signUp-page .selectize-dropdown .active {
  color: #fff;
  background: #1a1a1a;
}
.signUp-page .selectize-control.single .selectize-input:after {
  right: 8px;
}
.signUp-page .acType-content h4 {
  font-size: 28px;
}
.signUp-page .acType-content p {
  padding: 8px 0 25px;
}
.signUp-page .acType-content .acType-list {
  margin: 0 -22px;
}
.signUp-page .acType-content .acType-list li {
  float: left;
  padding: 0 22px;
}
.signUp-page .acType-content .acType-list li > div {
  position: relative;
}
.signUp-page .acType-content .acType-list li > div input {
  position: absolute;
  opacity: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.signUp-page .acType-content .acType-list li > div label {
  position: relative;
  font-size: 16px;
  line-height: 15px;
  color: rgba(47, 52, 62, 0.8);
  cursor: pointer;
  padding-left: 25px;
  margin: 0 0 15px;
  transition: all 0.1s ease-in-out;
}
.signUp-page .acType-content .acType-list li > div label:before {
  content: '';
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid #d6d6d6;
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.1s ease-in-out;
}
.signUp-page .acType-content .acType-list li > div input:checked + label:before {
  border-color: #ff3a46;
}
.signUp-page .acType-content .acType-list li > div input:checked + label {
  color: rgba(47, 52, 62, 1);
}
.signUp-page .acType-content {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 65px;
}
.signUp-page .agreement-checkbox label {
  position: relative;
  font-size: 16px;
  color: rgba(47, 52, 62, 0.6);
  cursor: pointer;
  padding-left: 22px;
  margin: 30px 0 35px;
  transition: all 0.1s ease-in-out;
}
.signUp-page .agreement-checkbox input[type='checkbox'] {
  display: none;
}
.signUp-page .agreement-checkbox label:before {
  content: '';
  width: 12px;
  height: 12px;
  line-height: 10px;
  border-radius: 2px;
  border: 1px solid #d5d5d5;
  font-size: 8px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 6px;
  transition: all 0.1s ease-in-out;
}
.signUp-page .agreement-checkbox input[type='checkbox']:checked + label:before {
  content: '';
  font-family: 'font-awesome';
  background: #373737;
  color: #fff;
  border-color: #373737;
}
.signUp-page .agreement-checkbox input[type='checkbox']:checked + label {
  color: #373737;
}
.signUp-page.signUp-minimal .agreement-checkbox label {
  margin-top: 0;
}
.signUp-page.signUp-minimal form .line-button-one {
  width: 100%;
  border-radius: 5px;
}
.signin-form-wrapper .title-area {
  margin-bottom: 50px;
}
.signUp-standard .signin-form-wrapper #login-form .input-group input {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #e0e0e0;
  padding-left: 0;
}
.signUp-standard .signin-form-wrapper #login-form .input-group label {
  left: 0;
}
.signUp-standard .signin-form-wrapper #login-form {
  padding-right: 170px;
}
#login-form .agreement-checkbox label {
  margin: 0;
}
#login-form .agreement-checkbox a {
  font-size: 16px;
  color: rgba(47, 52, 62, 0.6);
}
#login-form .agreement-checkbox a:hover {
  color: #212121;
}
#login-form .agreement-checkbox {
  margin-bottom: 55px;
}
.signin-form-wrapper .signUp-text {
  font-size: 20px;
  padding-top: 40px;
}
#login-form button {
  text-transform: uppercase;
}
.signUp-minimal .signin-form-wrapper {
  max-width: 520px;
  margin: 0 auto;
  border: 1px solid #e0e0e0;
  padding: 70px 65px 35px;
  position: relative;
}
.signUp-minimal .signin-form-wrapper .signUp-text {
  font-size: 17px;
  padding-top: 35px;
}

/*----------------------- Theme E-Commerce Menu ----------------------*/
.theme-Ecommerce-menu {
  position: relative;
  padding: 60px 60px 40px;
  z-index: 99;
}
.theme-Ecommerce-menu .left-content ul li,
.theme-Ecommerce-menu .right-content > ul > li {
  display: inline-block;
  vertical-align: middle;
}
.theme-Ecommerce-menu .left-content ul .menu-button {
  display: block;
  background: transparent;
  position: relative;
  margin: -8px 15px 0 0;
}
.eCommerce-search {
  width: 300px;
  position: relative;
}
.eCommerce-search input {
  width: 100%;
  border: none;
  font-style: italic;
  color: rgba(65, 65, 65, 0.33);
  padding-left: 30px;
  background: transparent;
}
.eCommerce-search .icon {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 20px;
  line-height: 27px;
  color: #2e2e2e;
}
.eCommerce-search ::placeholder {
  color: rgba(65, 65, 65, 0.33);
  opacity: 1;
}
.eCommerce-search :-ms-input-placeholder {
  color: rgba(65, 65, 65, 0.33);
}
.eCommerce-search ::-ms-input-placeholder {
  color: rgba(65, 65, 65, 0.33);
}
.theme-Ecommerce-menu .right-content .dropdown-toggle:after {
  display: none;
}
.theme-Ecommerce-menu .right-content .dropdown-toggle {
  background: transparent;
}
.theme-Ecommerce-menu .right-content .dropdown-menu {
  background: #fff;
  box-shadow: 0px 25px 50px 0px rgba(213, 216, 223, 0.5);
  border: 1px solid #f8f8f8;
  border-radius: 0;
  margin: 15px -35px 0 0;
}
.theme-Ecommerce-menu .right-content .cart-action-wrapper .dropdown-toggle {
  height: 27px;
  position: relative;
}
.theme-Ecommerce-menu .right-content .cart-action-wrapper .dropdown-toggle .item-count {
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #ff3612;
  line-height: 15px;
  text-align: center;
  font-size: 9px;
  color: #fff;
  right: -12px;
  top: -6px;
}
.theme-Ecommerce-menu .right-content .cart-action-wrapper .dropdown-menu {
  padding: 40px 35px;
  min-width: 300px;
}
.theme-Ecommerce-menu .right-content .cart-action-wrapper .cart-product-list .item-img {
  width: 70px;
  height: 80px;
  display: block;
  float: left;
}
.theme-Ecommerce-menu .right-content .cart-action-wrapper .cart-product-list .item-info {
  width: calc(100% - 70px);
  float: left;
  padding-left: 25px;
  position: relative;
}
.theme-Ecommerce-menu .right-content .cart-action-wrapper .cart-product-list .item-info .close {
  position: absolute;
  top: 13px;
  right: 0;
  font-size: 12px;
  color: rgba(31, 31, 31, 0.9);
}
.theme-Ecommerce-menu
  .right-content
  .cart-action-wrapper
  .cart-product-list
  .item-info
  .close:hover {
  color: #ff3612;
}
.theme-Ecommerce-menu .right-content .cart-action-wrapper .cart-product-list .item-info .name {
  font-size: 18px;
  color: #1f1f1f;
  margin: 5px 0;
  padding-right: 12px;
}
.theme-Ecommerce-menu .right-content .cart-action-wrapper .cart-product-list .item-info .price {
  font-size: 20px;
  color: #1f1f1f;
}
.theme-Ecommerce-menu
  .right-content
  .cart-action-wrapper
  .cart-product-list
  .item-info
  .price
  .quantity {
  font-size: 17px;
  color: rgba(31, 31, 31, 0.3);
  margin-left: 15px;
}
.theme-Ecommerce-menu .right-content .cart-action-wrapper .cart-product-list .selected-item {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.theme-Ecommerce-menu
  .right-content
  .cart-action-wrapper
  .cart-product-list
  .selected-item:last-child {
  margin-bottom: 14px;
}
.theme-Ecommerce-menu .right-content .cart-action-wrapper .subtotal .title {
  font-size: 18px;
  color: #1f1f1f;
}
.theme-Ecommerce-menu .right-content .cart-action-wrapper .subtotal .total-price {
  font-size: 20px;
  color: #1f1f1f;
}
.theme-Ecommerce-menu .right-content .cart-action-wrapper .subtotal {
  padding-bottom: 7px;
}
.theme-Ecommerce-menu .right-content .cart-action-wrapper .button-group a {
  font-family: 'CircularStdmed';
  display: block;
  line-height: 45px;
  border: 1px solid #dbdbdb;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  color: #1f1f1f;
  margin-top: 15px;
}
.theme-Ecommerce-menu .right-content .cart-action-wrapper .button-group a:hover {
  color: #fff;
}
.theme-Ecommerce-menu .right-content .user-profile-action {
  padding-left: 35px;
}
.theme-Ecommerce-menu .right-content .user-profile-action .dropdown-toggle img {
  display: inline-block;
}
.theme-Ecommerce-menu .right-content .user-profile-action .dropdown-toggle span {
  font-size: 17px;
  color: #414141;
  vertical-align: middle;
  padding-left: 10px;
  line-height: 16px;
}
.theme-Ecommerce-menu .right-content .user-profile-action .dropdown-menu {
  min-width: 220px;
  padding: 20px 25px 25px;
}
.theme-Ecommerce-menu .right-content .user-profile-action .dropdown-menu ul li a {
  font-size: 16px;
  line-height: 42px;
  color: #4b4c51;
  padding-left: 30px;
  position: relative;
}
.theme-Ecommerce-menu .right-content .user-profile-action .dropdown-menu ul li a .icon {
  position: absolute;
  left: 0;
  top: 11px;
}
.theme-Ecommerce-menu .right-content .user-profile-action .dropdown-menu ul li a:hover .icon path {
  fill: #ff3612;
}
