/*

*/
.rsc-float-button {
  background: black !important;
  border-radius: 5px !important;
  border: solid 5px transparent !important;
}

.highlightContainer {
  transition: 0.3s ease;
  animation: blink 1s;
  animation-iteration-count: 3;
  border: solid 3px transparent;
}

@keyframes blink {
  50% {
    border-color: red;
  }
}

/*Floating Avatar*/
.iVhDrr {
  height: 44px !important;
  width: 44px !important;
}

.sc-fzXfLR.rsc-header {
  background: #000000;
}

/*bot comment*/
.sc-AykKI.rsc-ts-bubble {
  border-radius: 5px;
  background: rgb(33, 33, 33);
  color: rgb(255, 255, 255);
}

/*bot options comment - .cGUwka*/
.sc-AykKF.rsc-os-option-element {
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  color: #fff;
}

/*user comment - .ckrkcX*/
.sc-AykKI.rsc-ts-bubble {
  border-radius: 5px;
  background: rgb(253, 253, 253);
  color: rgb(0, 0, 0);
  margin-right: 8px;
}

.gQFDUm {
  margin: 0px 0 10px 76px !important;
}

.hIZFCP {
  opacity: 0.4;
}

/*user avatar - .gOPpPO*/
.sc-AykKK.rsc-ts-image-container {
  /* display: none; */
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #161616;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}
