.op-project-one {
  padding: 150px 0 0 !important;
}

@media (max-width: 1024px) {
  .more-button {
    line-height: normal;
  }
}

@media (max-width: 768px) {
  .op-project-one .upper-heading p {
      text-align: center;
  }
}

@media (max-width: 414px) {
  .theme-title-four {
      margin-top: -70px !important;
  }

  .more-text .more-button {
    font-size: 18px !important;
    line-height: 28px;
  }
}

@media (max-width: 375px) {
  .theme-title-four {
    margin-top: -50px !important;
  }
}