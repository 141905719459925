.signaturePosition {
  position: initial;
  float: right;
  margin-top: -15px;
  margin-right: 30px;
}

/*
.signaturePos {
  position: absolute;
}
*/

.op-about-us-one .top-text {
  position: relative;
  padding: 0 0 75px 45px;
}

.font-lato {
  /*
  font-family: 'Lato', sans-serif !important;
  font-weight: normal !important;
  */
  font-weight: normal;
  margin: 0 0 1em;
  line-height: 1.4285em;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.profileTitle {
  font-family: 'Lato', sans-serif !important;
  line-height: 1.28571429em !important;
  margin: calc(2rem - 0.14285714em) 0 1rem !important;
  font-weight: 700 !important;
  padding: 0 !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.op-about-us-one .bottom-text {
  padding: 50px 0 0 45px;
  position: relative;
}

.theme-title-four {
  margin-bottom: 0px;
  line-height: 75px;
  font-size: 62px;
}

.theme-title-four span {
  position: relative;
  display: inline-block;
  font-size: 65px;
}

.theme-title-four span:after {
  width: 120px;
}

/*
.theme-title-four .font-k2d:after {
  content: '';
  width: 120px;
  height: 3px;
  position: absolute;
  background: #202020;
  top: 55%;
  right: -150px;
}
*/

/*-----------------------------------------------------------------------*/

/*
@import url("https://fonts.googleapis.com/css?family=DM+Sans:400,500|Jost:400,500,600&display=swap");
* {
  box-sizing: border-box;
}

body {
  color: #2b2c48;
  font-family: "Jost", sans-serif;
  background-image: url(https://images.unsplash.com/photo-1566738780863-f9608f88f3a9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2378&q=80);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 100vh;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}
*/
.op-about-us-one {
  min-height: 650px;
  padding-top: 0px;
}

.op-about-us-one .bottom-text p {
  padding-bottom: 10px;
}

/*
margin-bottom: -47px;
*/
.card {
  max-width: 100%;
  margin: auto;
  max-height: 470px;
  min-height: 470px;
  margin-right: 5%;
  overflow-y: hidden;
  position: relative;
  z-index: 1;
  overflow-x: hidden;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.2);
  background-color: white;
  display: -webkit-box;
  display: flex;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  border-radius: 0px;
  border: none;
}

.card[data-state='#about'] {
  border: none;
}
.card[data-state='#about'] .card-main {
  padding-top: 0;
  max-height: 350px;
}

.card[data-state='#experience'] .card-main {
  padding-top: 0;
  max-height: 350px;
}

.card[data-state='#contact'] {
  height: 430px;
}

.card[data-state='#experience'] {
  /*
  height: 550px;
  */
}

.card.is-active .card-header {
  height: 80px;
}
.card.is-active .card-cover {
  height: 100px;
  top: -50px;
}
.card.is-active .card-avatar {
  -webkit-transform: none;
  transform: none;
  left: 20px;
  width: 50px;
  height: 50px;
  bottom: 10px;
}
.card.is-active .card-fullname,
.card.is-active .card-jobtitle {
  left: 86px;
  -webkit-transform: none;
  transform: none;
}
.card.is-active .card-fullname {
  bottom: 18px;
  font-size: 19px;
}
.card.is-active .card-jobtitle {
  bottom: 16px;
  letter-spacing: 1px;
  font-size: 10px;
}

/* rgb(250, 250, 250); */
.card-header {
  background-color: rgb(250, 250, 250) !important;
  position: relative;
  display: -webkit-box;
  display: flex;
  height: 220px;
  flex-shrink: 0;
  width: 100%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.card-header * {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.card-cover {
  width: 100%;
  height: 100%;
  position: absolute;
  height: 160px;
  top: -20%;
  left: 0;
  will-change: top;
  background-size: cover;
  background-position: center;
  -webkit-filter: blur(30px);
  filter: blur(30px);
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.card-avatar {
  width: 150px;
  height: 150px;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  -o-object-position: center;
  object-position: center;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-60px);
  transform: translateX(-50%) translateY(-60px);
}

.card-fullname {
  position: absolute;
  bottom: 0;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  -webkit-transform: translateY(-10px) translateX(-50%);
  transform: translateY(-10px) translateX(-50%);
  left: 50%;
  margin: calc(2rem - 0.14285714em) 0 1rem;
  font-family: 'Lato', sans-serif !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.card-jobtitle {
  position: absolute;
  bottom: 0;
  font-size: 11px;
  white-space: nowrap;
  font-weight: 500;
  opacity: 0.7;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin: 0;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-7px);
  transform: translateX(-50%) translateY(-7px);
}

.card-main {
  /*
  max-height: 300px;
  */
  overflow-y: auto;
  position: relative;
  -webkit-box-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: flex;
  padding-top: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.card-subtitle {
  font-weight: 700;
  font-size: 13px;
  margin-bottom: 8px;
  font-family: 'Lato', sans-serif !important;
}

.card-content {
  padding: 20px;
}

.cardContentAbout {
  padding: 37px 0px;
}

.card-desc {
  line-height: 1.6;
  color: #636b6f;
  font-size: 14px;
  margin: 0;
  font-weight: 400;
  font-family: 'DM Sans', sans-serif;

  /*
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.4285em;
  color: rgba(0, 0, 0, 0.87);
  */
}

.card-social {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 30px;
}
.card-social svg {
  fill: #a5b5ce;
  width: 16px;
  display: block;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.card-social a {
  color: #8797a1;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(93, 133, 193, 0.05);
  border-radius: 50%;
  margin-right: 10px;
}
.card-social a:hover svg {
  fill: #637faa;
}
.card-social a:last-child {
  margin-right: 0;
}

.card-buttons {
  /*
  margin-top: auto;
  */
  display: -webkit-box;
  display: flex;
  background-color: #fff;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  left: 0;
  box-shadow: 0px 30px 50px 0px rgba(138, 138, 138, 0.1);
  font-size: 14px;
  line-height: 20px;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  height: 45px;
}
.card-buttons button {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: 0;
  font-size: 13px;
  border: 0;
  padding: 15px 5px;
  padding-bottom: 18px;
  cursor: pointer;
  color: #5c5c6d;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  font-family: 'Jost', sans-serif;
  font-weight: 500;
  outline: 0;
  border-bottom: 1px solid red;
  /*
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.2);
  */
}
.card-buttons button.is-active,
.card-buttons button:hover {
  /*
  border-bottom: 3px solid #8a84ff;
  #fdf3cb;
  */
  color: #ffffff;
  background: #242424;
}

.card-buttons button:hover {
  color: #242424;
  background: #fdf3cb;
}

.card-section {
  display: none;
}
.card-section.is-active {
  /*
  padding: 20px 0px;
  */
  display: block;
  -webkit-animation: fadeIn 0.6s both;
  animation: fadeIn 0.6s both;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    -webkit-transform: translatey(40px);
    transform: translatey(40px);
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    -webkit-transform: translatey(40px);
    transform: translatey(40px);
  }
  100% {
    opacity: 1;
  }
}
.card-timeline {
  margin-top: 30px;
  position: relative;
}
/*#516acc*/
.card-timeline:after {
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(134, 214, 243, 0)),
    to(#000000)
  );
  background: linear-gradient(to top, rgba(134, 214, 243, 0) 0%, #000000 100%);
  content: '';
  left: 42px;
  width: 2px;
  top: 0;
  height: 100%;
  position: absolute;
  content: '';
}

.card-item {
  position: relative;
  padding-left: 60px;
  padding-right: 20px;
  padding-bottom: 30px;
  z-index: 1;
}
.card-item:last-child {
  padding-bottom: 5px;
}
.card-item:after {
  content: attr(data-year);
  width: 10px;
  position: absolute;
  top: 0;
  left: 39px;
  width: 8px;
  height: 8px;
  line-height: 0.6;
  border: 2px solid #fff;
  font-size: 11px;
  text-indent: -60px;
  border-radius: 50%;
  color: rgba(134, 134, 134, 0.7);
  /*
  background: -webkit-gradient(linear, left top, left bottom, from(#a0aee3), to(#516acc));
  background: linear-gradient(to bottom, #a0aee3 0%, #000000 100%);
  */
  background: -webkit-gradient(linear, left top, left bottom, from(#ff0000), to(#ff0000));
  background: linear-gradient(to bottom, #ff0000 0%, #c20d0d 100%);
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.card-item-title {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
  color: black;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.card-item-desc {
  font-size: 13px;
  color: #6f6f7b;
  line-height: 1.5;
  font-family: 'DM Sans', sans-serif;
}

.card-item-desc p {
  margin: 0 0 1em;
  line-height: 1.4285em;
}

.card-contact-wrapper {
  margin-top: 20px;
}

.card-contact {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: 13px;
  color: #6f6f7b;
  font-family: 'DM Sans', sans-serif;
  line-height: 1.6;
  cursor: pointer;
}
.card-contact + .card-contact {
  margin-top: 16px;
}
.card-contact svg {
  flex-shrink: 0;
  width: 30px;
  min-height: 34px;
  margin-right: 12px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  padding-right: 12px;
  border-right: 1px solid #dfe2ec;
}

.contact-me {
  border: 0;
  outline: none;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(83, 200, 239, 0.8)),
    color-stop(96%, rgba(81, 106, 204, 0.8))
  );
  background: linear-gradient(to right, rgba(83, 200, 239, 0.8) 0%, rgba(81, 106, 204, 0.8) 96%);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  color: #fff;
  padding: 12px 16px;
  width: 100%;
  border-radius: 5px;
  margin-top: 25px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

/*
*  scrollbar
*/

.card-main::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 0px;
}

.card-main::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
  border-radius: 0px;
}

.card-main::-webkit-scrollbar-thumb {
  background-color: #242424;
  border-radius: 0px;
}

.welcomTitle {
  color: rgb(152, 157, 160) !important;
  font-weight: 600 !important;
}

.theme-title-three-pd {
  margin: 70px 0 70px;
}

@media screen and (max-width: 1200px) {
  .theme-title-four {
    margin-bottom: 0px;
    line-height: 55px;
    font-size: 52px;
  }

  .theme-title-four span:after {
    display: none;
  }
}

@media screen and (min-width: 1920px) {
  .op-about-us-one .top-text p {
    width: 380px;
  }
}


@media screen and (max-width: 1440px) {
  .op-about-us-one .top-text p {
    width: 440px;
  }
}

@media screen and (max-width: 1366px) {
  .op-about-us-one .top-text p {
    width: 423px;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .op-about-us-one .top-text p {
    width: 328px;
  }
}

@media screen and (max-width: 1000px) {
  .theme-title-four {
    text-align: center;
  }

  .theme-title-four {
    margin-bottom: 70px !important;
  }

  .card {
    margin-right: 0%;
    padding: 0px 0 0 45px;
  }

}

@media screen and (max-width: 768px) {
  .title-four-media {
    font-size: 56px !important;
    line-height: 57px !important;
  }

  
  .op-about-us-one .top-text p {
    font-size: 21px;  
    line-height: 38px;
    width: auto;
  }

  .theme-title-three-pd {
    margin: 100px 0 100px;
  }
}

@media screen and (max-width: 414px) {
  .title-four-media {
    font-size: 42px !important;
    line-height: 52px !important;
  }

  .op-about-us-one .top-text p {
    font-size: 20px;
    line-height: 36px;
    width: 355px;
  } 

  .theme-title-three .title {
    font-size: 32px;
  }

  .card.is-active .card-fullname {
    font-size: 17px;
  }
}

@media screen and (max-width: 375px) {
  .title-four-media {
    font-size: 45px !important;
    line-height: 50px !important;
    padding-left: 20px;
  }

  .op-about-us-one .top-text p {
    font-size: 17px;  
    line-height: 38px;
    width: 303px;
  }

  .op-about-us-one {
    margin-bottom: 100px;
  }
  
  .theme-title-four span {
    font-size: 55px;
  }

  .card.is-active .card-fullname {
    font-size: 14px;
  }
}

@media screen and (max-width: 320px) {
  .op-about-us-one .top-text p {
    width: 260px;
  }

  .title-four-media {
    font-size: 40px !important;
    line-height: 41px !important;
  }

  .op-about-us-one .top-text p {
    font-size: 15px;  
    line-height: 38px;
  }

  .op-about-us-one {
    margin-bottom: 100px;
  }

  .card-fullname {
    font-size: 15px;
  }

  .card.is-active .card-fullname {
    font-size: 10px;
  }
}
