#op-hero-one .carousel-nav {
  position: absolute;
  width: 140px;
  /* height: 700px !important; */
  max-height: 713px;
  background: #fff;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  top: 0;
  right: 0;
  z-index: 1;
}

#op-hero-one .carousel-control-next,
#op-hero-one .carousel-control-prev {
  cursor: pointer;
  position: initial;
  margin: 60px 0;
  width: 50%;
  margin-left: 25%;
}

#op-hero-one {
  height: 840px;
  position: relative;
  overflow: hidden;
  margin-top: -15px;
}

.carousel-slide {
  background-size: cover !important;
  min-height: 700px !important;
  cursor: pointer;
}

.carousel-slider-text {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 111pt;
  color: white;
  position: absolute;
  bottom: 30px;
  transform: translate(60%);
}

.blogTitle {
  margin-bottom: 20px !important;
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  font-weight: 700;
  line-height: 30.8571px;
}

.more-in-blog-post {
  width: 280px;
  line-height: 65px;
  background: #fdf3cb;
  font-size: 18px;
  color: #857a47;
  padding: 0 20px 0 30px;
  margin-top: 20px;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
}

.more-in-blog-post:hover {
  background: #242424;
  color: #fff;
}

.center {
  display: flex;
  justify-content: center;
}

.more-in-blog-post .flaticon-next-1 {
  margin-left: 20px;
}

.blogPostDesc p {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 14px;
}

.blogPostDesc h3 {
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
  font-weight: 700;
  line-height: 23.1429px;
  margin-top: 25.4286px;
  margin-bottom: 14px;
}

.greyCentreText {
  font-size: 24px;
  line-height: 40px;
  color: #798598;
  padding-top: 45px;
  padding-top: 45px;
  text-align: center;
}

.workedAt:before {
  content: '';
  width: 50px;
  height: 2px;
  position: absolute;
  background: #989da8;
  top: 50%;
  left: -60px;
}

@media screen and (max-width: 1000px) {
  #op-hero-one {
    display: none;
  }
}

@media screen and (max-width: 320px) {
  /* #op-hero-one {
    height: 800px !important;
  } */

  .more-in-blog-post {
    font-size: 14px;;
  }
}