#myBtn {
  position: fixed;
  bottom: 50px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: rgb(255, 255, 255);
  border: 4px double black;
  color: rgb(0, 0, 0);
  cursor: pointer;
  padding: 13px;
  border-radius: 4px;

  animation-name: apearTopButton;
  -moz-animation-name: apearTopButton;
  -webkit-animation-name: apearTopButton;
  animation-duration: 1.5s;
  -moz-animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
}

@keyframes apearTopButton {
  0% {
    color: rgb(0, 0, 0);
    opacity: 0;
  }

  100% {
    color: rgb(0, 0, 0);
    opacity: 1;
  }
}

@-moz-keyframes apearTopButton {
  0% {
    color: rgb(0, 0, 0);
    opacity: 0;
  }

  100% {
    color: rgb(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes apearTopButton {
  0% {
    color: rgb(0, 0, 0);
    opacity: 0;
  }

  100% {
    color: rgb(0, 0, 0);
    opacity: 1;
  }
}

#myBtn:hover {
  background-color: #c2c2c2;
  color: rgb(248, 248, 248);
}

/*------------------------------ style for imperative visible button -------------------------------*/
/*
#myBtn {
  transition: all 0.3s linear;
  opacity: 0;
  position: fixed;
  bottom: 100px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: red;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
}

#myBtn:hover {
  background-color: #555;
}

#myBtn.apearUpButton {
  transition: all 0.3s linear;
  opacity: 1;
}

*/
