.motivateTitle {
  margin: 0 0 1em;
  line-height: 1.4285em;
  /*
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
  */
}

.op-banner-one .credit-tag:before {
  top: 2px !important;
}

/*---------------------------------- Formik ---------------------------------------*/
.op-banner-one .banner-form {
  min-height: 480px !important;
}

.contactFormInoutWrap {
  margin-bottom: 0px;
}

.form-control {
  border-radius: 0px !important;
}

.form-control.is-invalid ~ .invalid-feedback {
  /*
  margin-bottom: 10px !important;
  float: left !important;
  */
  margin-top: -8px !important;
  position: absolute;
}

.op-banner-one .banner-form .input-group-wrapper {
  position: relative !important;
}

.op-banner-one .banner-form .input-group-wrapper input {
  margin-bottom: 10px !important;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.op-banner-one .banner-form .input-group-wrapper textarea {
  margin-top: 15px !important;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.invalid-feedback {
  font-size: 1rem c;
}

/*
.invalid-feedback {
}
*/

.contactFormHighlightContainer {
  transition: 1s ease;
  animation-name: blink;
  border-bottom: solid 3px red !important;
  /*
  border-bottom-color: red !important;
  animation-iteration-count: 40;
  border: solid 3px transparent !important;
  background-color: rgb(197, 194, 35);
  animation: blink 1s;
  border-color: red !important;
  */
}

@keyframes blink {
  50% {
    /*
    background-color: red !important;
    */
    border-color: red !important;
  }
}
/*
      50% {
        border-color: red;
        background-color: red !important;
      }
      #contentTextarea {
        position: relative;
      }
*/

.textApear {
  background-color: rgb(255, 255, 255);
  position: relative;
  animation-name: example;
  animation-duration: 3s;
}

@keyframes example {
  0% {
    font-size: 1px;
    color: rgb(0, 0, 0);
  }

  100% {
    font-size: 18px;
    color: rgb(0, 0, 0);
  }
}
/*
transition: 4s ease;
  25% {
    font-size: 3pt;
    color: rgb(219, 0, 0);
  }
  50% {
    font-size: 5pt;
    color: rgb(134, 0, 0);
  }
  75% {
    font-size: 8pt;
    color: rgb(44, 0, 0);
  }
*/
@media screen and (max-width: 768px) {
  .top-main-title {
    font-size: 58px !important;
    line-height: 68px !important;
  }
  .op-banner-one .main-title span:before {
    height: 88%;
    top: 13px;
}

}

@media screen and (max-width: 375px) {
  .top-main-title {
    font-size: 45px !important;
    line-height: 55px !important;
  }
}





