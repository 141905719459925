.op-project-one #isotop-gallery-wrapper .grid-sizer,
.op-project-one #isotop-gallery-wrapper .isotop-item {
  width: 100%;
  padding: 0 35px;
  margin-bottom: 70px;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: rgb(255, 255, 255);
  margin-bottom: 30px;
}

.bg-wrapper {
  padding: 80px 0 110px !important;
}

.op-project-one #isotop-gallery-wrapper .item-content .text {
  box-shadow: -15.045px 19.966px 40px 0px rgba(14, 11, 11, 0.26);
}

.op-project-one .more-text {
  margin-top: 0px;
}

/*
.op-project-one #isotop-gallery-wrapper .item-content .text h3 {
  font-size: 35px !important;
}
*/

.text {
  padding: 30px 35px 30px 30px !important;
}

.text p {
  margin: 0 0 1em;
  line-height: 1.4285em;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-weight: 400;
  /* height: 38px; */
  line-height: 19.999px;
}

.icon-box {
  left: 40px !important;
}

.read-more {
  right: 12px !important;
}

.title {
  font-weight: 700;
}

.massonryTitle {
  font-size: 35px !important;
  margin-top: 23px;
  font-family: 'Lato', sans-serif;
  line-height: 1.28571429em;
  margin: calc(2rem - 0.14285714em) 0 1rem;
  font-weight: 700;
  padding: 0;
  color: #303030;
  margin-bottom: 15px;
}

.svg-inline--fa {
  vertical-align: -0.25em;
}

/*--------------------------------------------------------------------

.top-container {
  background-color: #f1f1f1;
  padding: 30px;
  text-align: center;
}

.header {
  padding: 10px 16px;
  background: #555;
  color: #f1f1f1;
}

.content {
  padding: 16px;
}

.sticky {
  position: fixed;
  top: 100;
  width: 100%;
}

.sticky + .content {
  padding-top: 102px;
}

*/

@media screen and (max-width: 1024px) {
  .my-masonry-grid_column > div {
    background: none;
  }
}

@media screen and (max-width: 414px) {
  .theme-title-three p {
    font-size: 18px;
    line-height: 35px;
    padding: 20px 10px 40px 10px;
  }
}

@media screen and (max-width: 375px) {
  .theme-title-three {
    margin-bottom: 30px !important;
  }

  .massonryTitle {
    font-size: 24px !important;
  }
}

@media screen and (max-width: 320px) {
  .text {
    display: none;
  }

  .item-content {
    padding-bottom: 0px !important;
  }
}