/*
.ReactModal__Content {
  overflow: hidden !important;
}
*/
/*----------------------------------------------------*/

.overflow {
  overflow-y: auto;
  width: 90vh;
  height: 600px;
  border: 1px solid black;
  padding: 10px;
}

.overflow::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background-color: #f5f5f5 !important;
}

.overflow::-webkit-scrollbar {
  width: 6px !important;
  background-color: #f5f5f5 !important;
}

.overflow::-webkit-scrollbar-thumb {
  background-color: #000000 !important;
  border-radius: 0% !important;
}

/*----------------------------------------------------*/

.tools {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.tools__item {
  background: rgb(0, 0, 0);
  color: #ffffff;
  padding: 10px;
  margin-right: 5px;
  margin-bottom: 7px;
}

.slick-arrow {
  background-color: tomato;
}

@media (max-width: 768px)  {
  .massonryTitle {
    font-size: 20px !important;
  }

  .text {
      display: none;
  }
 
  .op-project-one #isotop-gallery-wrapper .item-content .text .icon-box {
    width: 40px;
    height: 40px;
    top: -20px;
    line-height: 34px;
  }

  .theme-title-three p {
    padding: 45px;
  }
}

@media (max-width: 768px) {
  .op-project-one #isotop-gallery-wrapper .item-content {
    position: relative;
    padding-bottom: 0;
  }
}

@media (max-width: 375px) {
.theme-title-three p {
    padding: 37px;
 }
  .op-project-one {
    padding: 100px 0 0 !important;
  }

  .op-project-one .upper-heading p {
    text-align: center;
    padding: 0 20px 0 20px;
  }
} 