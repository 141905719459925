.op-services-one .list-item ul li {
  display: flex !important;
}

.op-services-one {
  padding: 200px 0 80px;
}


@media screen and (max-width: 1024px) {
  .op-services-one .title-text {
    font-size: 160px;
  }
}

@media screen and (max-width: 768px) {
  .op-services-one .title-text {
    margin-bottom: 60px;
  }
}


@media screen and (max-width: 414px) {
  .op-services-one  {
    padding: 110px 0 80px !important;
    text-align: center;
  }

  .op-services-one .title-text {
    font-size: 80px !important;
}

  .theme-title-four {
    padding-top: 30px !important;
    line-height: 52px !important;
    font-size: 42px !important;
  }

  .op-services-one p {
    padding: 20px 26px 60px;
  } 
}

@media screen and (max-width: 375px) {
.op-services-one .title-text {
    margin-bottom: 40px;
    font-size: 75px;
    text-align: center;
  }

  .theme-title-four {
    font-size: 45px !important;
    line-height: 50px !important;
    padding-left: 20px !important;
    margin-bottom: 80px !important;
  }

  .op-services-one p {
    font-size: 20px;
    line-height: 38px;
    text-align: center;
    padding: 20px 37px 60px;
  } 

  .font-k2d {
    text-align: center;
  }
}

@media screen and (max-width: 320px) {
    .theme-title-four {
      padding-left: 10px;
    }

    .op-services-one {
      padding: 100px 0 100px !important;
    }

    .op-services-one .list-item {
      padding-bottom: 60px;
    }
    .op-project-one .upper-heading p {
      text-align: center;
      font-size: 22px;
  }
}