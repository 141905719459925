.activeMenuLink {
  columns: red;
}

.main-menu-list ul li p {
  font-family: 'Lato', sans-serif;
  margin: 0 0 1em;
  line-height: 1.4285em;
  margin-bottom: 0px;
}

@media (max-width: 768px)  {
.op-menu-one {
    padding: 25px 70px 0 0;
  }
}