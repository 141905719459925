#app div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  text-transform: uppercase;
}

input {
  padding: 5px;
  font-size: 16px;
}

input:not(:first-of-type) {
  margin-left: 20px;
}

video {
  margin: 30px;
  width: 500px;
  max-width: 85%;
}
