.partnerSocialMain {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.partnerSocialMain__item {
  color: #bfbfbf !important;
}

.partnerSocialMain .partnerSocialMain__item a {
  color: #bfbfbf !important;
  font-size: 25px !important;
  margin-top: 18px !important;
  margin-right: 10px !important;
}

.op-testimonial-one .client-text-box {
  padding: 50px 30px 45px 60px !important;
}

.op-testimonial-one .theme-title-four {
  margin-bottom: 0px !important;
}

.op-testimonial-one p {
  font-size: 24px;
  line-height: 35px;
  color: #2c2c2c;
  padding: 40px 0 50px;
}

.client-text-box blockquote {
  color: rgb(44, 44, 44);
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 24px;

  font-weight: 400;
  line-height: 38px;
}

.client-text-box .desg {
  color: rgb(183, 183, 183);
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-feature-settings: 'tnum', 'tnum';
  font-size: 20px;
  font-weight: 400;
  height: 38px;
  line-height: 19.999px;
  text-align: left;
}


.partnerName {
  position: absolute;
  top: 39%;
  margin-left: 4px;
  padding-top: 8px;
  font-size: smaller;
}


@media screen and (max-width: 1024px) {
  .partnerName {
    top: 38% !important;
  }

  .op-partner-section-one {
    display: none;
}
}

@media screen and (max-width: 768px) {
  .partnerSocialMain__item {
    padding-top: 20px;
  }

  .partnerName {
    top: 37% !important;
  }

  .op-partner-section-one {
    display: none;
}
}

@media screen and (max-width: 414px) {
  .partnerName {
    top: 37% !important;
  }

  .op-testimonial-one p {
    padding: 80px 0 50px;
    text-align: center;
  }
}

@media screen and (max-width: 375px) {
  .op-testimonial-one p {
    font-size: 22px;
    text-align: center;
  }
  .op-testimonial-one .client-text-box .desg {
    font-size: 18px;
  }

  .op-testimonial-one .client-text-box blockquote {
    font-size: 22px;
    line-height: 34px;
  }

  .partnerName {
    top: 36% !important;
  }
}

@media screen and (max-width: 320px) {
  .partnerName {
    top: 37% !important;
  }
}