.selected {
  line-height: 1.5;
  color: #212529 !important;
}

.lang-en.lang-select {
  /* cursor: url(images/sniper.cur), pointer; */
  cursor: pointer;
}

.font-k2d.main-title {
  max-width: 800px;
}

.input-group-wrapper input:not(:first-of-type) {
  margin-left: 0px;
}

@media (max-width: 700px) {
  .op-banner-one .main-title {
    font-size: 55px;
  }
}
